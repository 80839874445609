import Vue from "vue";
import VueI18n from 'vue-i18n'

import de from '../langs/de/index.js';
import en from '../langs/en/index.js';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de'
});

const languages = [
  {local: 'de', obj: de},
  {local: 'en', obj: en},
]

for (let i = 0; i < languages.length; i++) {
  i18n.setLocaleMessage(languages[i].local, languages[i].obj);
}
