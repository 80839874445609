import * as Service from '@/services/customer/customer_gateway_service'

export default {
    namespace: true,
    state: {
        customers: [],
    },
    getters: {
        customers(state) {
            return state.customers
        }
    },
    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers
        }
    },
    actions: {
        /* Customers */
        async customers({commit, state}, page) {
            // if (state.customers.length === 0) {
                await Service.customers(page).then(response => {
                    commit('SET_CUSTOMERS', response.data)
                })
            // }
        },

        /* Customers */
        async customerSearch({commit, state}, data) {
            await Service.customerSearch(data).then(response => {
                commit('SET_CUSTOMERS', response.data)
            })
        },
    }
}
