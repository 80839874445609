import { http } from "../http_service";


/** COMPANIES */
export function companies(page) {
    return http().get(`/companies?page=${page}`);
}

/** COMPANY SHOW */
export function companyShow(id) {
    return http().get(`/companies/${id}/show`);
}

/** COMPANY SEARCH */
export function companySearch(data) {
    return http().post(`/companies`, data);
}

/** COMPANY SEARCH FROM CALL */
export function companySearchFromCall(data) {
    return http().post(`/companies/search-call`, data);
}

/** COMPANY */
export function companyFromCustomer(customer_id) {
    return http().get(`/companies/${customer_id}`);
}

/** ADDRESS  */
export function addresses() {
    return http().get(`/addresses`);
}

/** PERSON */
export function persons() {
    return http().get(`/contact-persons`);
}


/** QUESTIONS PER FAQ ID */
export function questionPerFaqId(id) {
    return http().get(`/faq/${id}/questions`)
}
