export default {
    "status": {
        "active": "Aktiv",
        "not_active": "Inaktiv",
    },
    "search": {
        "label": "Suche...",
        "placeholder": "Suche...",
        "select": {
            "position": "Position",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "email": "Email",
            "identification": "Personalnummer"
        },
    },
    "table": {
        "not_found": "Sie haben noch keinen registrierten Kunden",
        "identification": "Personalnummer",
        "first_name": "Vorname",
        "last_name": "Nachname",
        "email": "Email",
        "full_name": "Vorname Nachname",
        "birthday": "Geburtstag",
        "gender": {
            "title": "Anrede",
            "female": "Frau",
            "male": "Mann",
            "male_hr": "Herr",
            "divers": "Divers",
            "indefinite": "Unbestimmt"
        },
    },
    "show": {
        "identification": "Personalnummer",
        "first_name": "Vorname",
        "last_name": "Nachname",
        "email": "Email",
        "full_name": "Vorname Nachname",
        "birthday": "Geburtstag",
        "gender": {
            "title": "Anrede",
            "female": "Frau",
            "male": "Mann",
            "male_hr": "Herr",
            "divers": "Divers",
            "indefinite": "Unbestimmt"
        },
    }
}