import {i18n} from "@/plugins/i18n";

export default {
  set(language) {
    import(`@/langs/${language}/index.js`).then((ms) => {
      i18n.setLocaleMessage(language, ms.default);
      i18n.locale = language;
    });
  }
}
