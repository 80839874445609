<template>
  <div class="main-layout">
    <DrawerLeft />
    <Header/>
    <v-main>
      <router-view/>
    </v-main>
    <v-dialog
        v-model="dialog_task"
        width="1366"
        persistent
        style="z-index: 99999"
    >
      <TaskCreateDialogComponent v-if="$store.getters.getStatusTaskDrawer" />
    </v-dialog>

    <v-dialog
        v-model="dialog_task_update"
        width="1366"
        persistent
        style="z-index: 99999"
    >
      <TaskUpdateDialogComponent v-if="$store.getters.getStatusTaskUpdateDialog" />
    </v-dialog>
  </div>
</template>

<script>

import Header from "@/components/Header";
import DrawerLeft from "@/components/DrawerLeft";
import {reportWatcher} from "@/scripts/watcher_report";

import TaskCreateDialogComponent from "@/module/task/components/Task/TaskCreateDialogComponent";
import TaskUpdateDialogComponent from "@/module/task/components/Task/TaskUpdateDialogComponent";

export default {
  components: {Header, DrawerLeft, TaskCreateDialogComponent, TaskUpdateDialogComponent},
  data() {
    return {}
  },
  mounted() {
    reportWatcher();
  },
  destroyed() {},
  computed: {
    dialog_task: {
      get () {
        return this.$store.getters.getStatusTaskDrawer
      },
      set (val) {
        this.$store.commit('setStatusTaskDrawer', val)
      }
    },
    dialog_task_update: {
      get () {
        return this.$store.getters.getStatusTaskUpdateDialog
      },
      set (val) {
        this.$store.commit('setStatusTaskUpdate', val)
      }
    }
  },
  methods: {
    darkTheme(bool) {
      this.$vuetify.theme.dark = bool
    },
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
  },
}
</script>


<!--<style lang="scss">-->

<!--.mx-call-btn {-->
<!--  animation: glowing 1300ms infinite;-->
<!--}-->

<!--@keyframes glowing {-->
<!--  0% { background-color: #a80505; box-shadow: 0 0 5px #a80505; }-->
<!--  50% { background-color: #e81919; box-shadow: 0 0 20px #e81919; }-->
<!--  100% { background-color: #a80505; box-shadow: 0 0 5px #a80505; }-->
<!--}-->

<!--</style>-->
