<template>
  <div class="mx-checklist">
    <div v-if="!$store.getters.getIsObserve && this.task_status !== 'completed'" class="d-flex mx-checklist-top" style=" margin-bottom: 20px; background: transparent;">
      <input type="text" class="mx-checklist-input" v-model="name" :placeholder="$t('task.checklist.add_label')">
      <v-btn :disabled="btn_disable" :loading="btn_loading" @click="addChecklistItem" class="mx-checklist-top__btn" elevation="0" small color="blue text">
        <v-icon size="14">fas fa-plus</v-icon>
      </v-btn>
    </div>
    <div style="height: 440px; overflow-y: auto; background: transparent;">
      <div class="mx-checklist-item" v-for="item in check_list" :key="item.id">
        <div class="mx-checklist-item-title">
          <v-checkbox
              :disabled="!isUpdater(item) || $store.getters.getIsObserve || task_status === 'completed'"
              class="mx-checklist-checkbox"
              hide-details
              :ripple="false"
              :false-value="0"
              :true-value="1"
              v-model="item.status"
              @click="checkboxUpdate(item)"
              v-if="module_update"
          ></v-checkbox>
          <input
              :disabled="!isDisabled(item) || $store.getters.getIsObserve || task_status === 'completed'"
              type="text"
              :class="{'mx-checklist-item-input__disabled': !isDisabled(item)}"
              class="mx-checklist-input mx-checklist-item-input"
              v-model="item.name"
              :placeholder="$t('task.checklist.add_label')"
          >
          <v-icon
              @click="deleteChecklistItem(item.id)"
              v-if="isDisabled(item) && task_status !== 'completed'"
              size="16" class="mx-checklist-item-icon__trash ml-3 mr-2"
          >
            fas fa-times
          </v-icon>
        </div>
        <div class="mx-checklist-item__subtitle">

          <div class="mx-checklist-item__subtitle__item mx-checklist-item__subtitle-creator">
            <span>{{ $t('task.checklist.created_by') }}</span>
            <strong>{{ getCreator(item) }}</strong>
          </div>

          <div class="mx-checklist-item__subtitle__item mx-checklist-item__subtitle-create_at">
            <span>{{ $t('task.checklist.create_time') }}</span>
            <strong>{{ item.create_at }}</strong>
          </div>

          <div v-if="item.status" class="mx-checklist-item__subtitle__item mx-checklist-item__subtitle-executor">
            <span>{{ $t('task.checklist.executor') }}</span>
            <strong>{{ getUpdater(item) }}</strong>
          </div>

          <div v-if="item.status" class="mx-checklist-item__subtitle__item mx-checklist-item__subtitle-create_at">
            <span>{{ $t('task.checklist.update_time') }}</span>
            <strong>{{ item.update_at }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TaskChecklistComponent",
  props: {
    creator: {
      required: true,
      default: () => {}
    },
    module_update: {
      required: false,
      default: false
    },
    executors: {
      required: false,
      default: () => []
    },
    update_checklist: {
      required: false,
      default: () => []
    },
    task_status: {
      required: false,
      default: 'todo'
    }
  },
  data() {
    return {
      name: "",
      btn_disable: true,
      btn_loading: false,
      check_list: this.update_checklist.length > 0 ? this.update_checklist : []
    }
  },
  watch: {
    name() {
      this.btn_disable = this.name.length < 5;
    },
    check_list: {
      handler(val){
        this.sendChecklist()
      },
      deep: true
    }
  },
  methods: {
    checkboxUpdate(el) {
      const item = this.check_list.find(item => item.id === el.id)
      if (item.executor_uid === null) {
        item.executor_uid = this.$store.getters.user.uid
        item.update_at = moment().format('DD.MM.YYYY HH:mm')
      } else {
        item.executor_uid = null
        item.update_at = null
      }
    },
    isDisabled(el) {
      return el.status === 0 && el.creator_uid === this.$store.getters.user.uid
    },
    isChecked(status) {
      return status === 1
    },
    isUpdater(el) {
      return el.executor_uid === this.$store.getters.user.uid || el.executor_uid === null
    },
    sendChecklist() {
      this.$emit('getChecklistItems', this.check_list)
    },
    addChecklistItem() {
      this.btn_disable = true
      this.btn_loading = true
      this.check_list.push(
          {
            id: moment().format('mmss'),
            // id: moment().valueOf(),
            name: this.name,
            create_at: moment().format('DD.MM.YYYY HH:mm'),
            update_at: null,
            creator_uid: this.creator.uid,
            status: 0,
            executor_uid: null
          }
      )
      this.name = ''

      setTimeout(() => {
        this.btn_loading =false
      }, 300)
    },
    deleteChecklistItem(item_id) {
      this. check_list = this.check_list.filter(item => item.id !== item_id)
    },
    getCreator(el) {
      const executor = this.executors.find(ex => ex.uid === el.creator_uid)
      return executor.first_name[0] + '.' + executor.last_name
    },
    getUpdater(el) {
      const executor = this.executors.find(ex => ex.uid === el.executor_uid)
      return executor.first_name[0] + '.' + executor.last_name
    }
  }
}
</script>

<style lang="scss">
.mx-checklist {
  &-top {
    &__btn {
      height: 42px !important;
      width: 48px;
      margin-left: 10px;
    }
  }
  &-input {
    border: 1px solid;
    height: 42px;
    border-radius: 4px;
    padding: 6px 12px;
    width: 100%;
  }
  &-checkbox {
    margin-top: 0;
    margin-right: 2px;
  }
  &-item {
    border: 1px dashed;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    &-title {
      display: flex;
      align-items: center;
    }
    &-input {
      height: 36px;
      font-size: 13px;
    }

    &__subtitle {
      font-size: 11px;
      //padding-left: 40px;
      margin-top: 6px;
      display: flex;
      background: #f8f8f8;
      padding: 3px 6px;
      border-radius: 2px;
      &__item {
        margin-right: 30px;
        span {
          margin-right: 4px;
        }
      }
    }
  }
}

.theme--light {
  $border-color: #cecece;

  .mx-checklist {
    &-input {
      border-color: rgba($border-color, 0.5);
    }
    &-item {
      border-color: rgba($border-color, 0.9);

      &-input {
        color: #3d3d3d;
        &__disabled {
          //color: #bdbdbd !important;
          border-color: rgba($border-color, 0.2);
          background-color: rgba($border-color, 0.2);
        }
      }
      &__subtitle {
        color: #707070;
      }
      &-icon {
        &__trash {
          margin-left: 10px;
          color: #5b5a5a !important;
          &:hover {
            color: #ec4343 !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.theme--dark {
  $border-color: #666666;

  .mx-checklist {
    &-input {
      border-color: $border-color;
      color: #bdbdbd;
    }
    &-item {
      border-color: rgba($border-color, 0.9);

      &__subtitle {
        color: #bdbdbd;
        background: #393939;
      }
    }
  }
}

</style>
