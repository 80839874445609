import {can} from "@/middleware/access_middleware";

export default {
    state: {
        drawer_left: true,
        menu: [
            {
                title: 'Dashboard',
                icon: 'mdi-view-dashboard',
                icon_status: true,
                url: '/dashboard',
                // can: can('dashboard-show')
                can: true
            },
            {title: 'Dokumente', icon: 'mdi-folder-outline', url: '/documents', icon_status: true, can: true},
            // { title: 'Users', icon: 'mdi-account', url: '/users', can: true },
            // { title: 'Mitarbeiter', icon: 'mdi-account-tie', url: '/employees', can: true },
            // { title: 'Kunden', icon: 'mdi-account-group', url: '/customers', can: true },
            // { title: 'Ansprechpartner', icon: 'mdi-account', icon_status: true, url: '/companies', can: true },
            {title: 'Kunden', icon: 'mdi-account', icon_status: true, url: '/companies', can: true},
            {title: 'Meine Berichte', icon: 'mdi-file-document-outline', icon_status: true, url: '/reports', can: true},
        ],
        user_settings: {
            header: {
                profile_avatar: false
            }
        },
        task: {
            table: {
                group_by: false
            }
        }
    },
    getters: {
        drawer_left(state) {
            return state.drawer_left
        },
        menu(state) {
            return state.menu
        },
        systemTask(state) {
            return state.task
        },
        user_settings(state) {
            return state.user_settings
        }
    },
    mutations: {
        set_drawer_left(state, bool) {
            state.drawer_left = bool
        }
    },
    actions: {}
}
