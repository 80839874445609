import store from '@/store'

function show({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'customer-company-show') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function create({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'customer-company-create') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function update({next}) {
  if (store.getters.auth_permissions.find((per) => per === 'customer-company-update') === undefined) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

export { show, create, update }
