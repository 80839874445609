/* Middleware */
import guest from '@/middleware/guest';

export default [
    {
        path: '/',
        name: 'login',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/LoginCenterView')
    },
    {
        path: '/unlock',
        name: 'unlock',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/UnlockView')
    },
    {
        path: '/auth/password/forgot',
        name: 'password.forgot',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/PasswordForgotView')
    },
    {
        path: '/auth/password/verify',
        name: 'password.verify',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/PasswordVerifyView')
    },
    {
        path: '/auth/password/reset',
        name: 'password.reset',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('@/views/auth/PasswordResetView')
    },
];