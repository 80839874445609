export default {
    "language": "Sprache",
    "title": "Notizen",
    "title_last": "Neue Notizen",
    "notice_about_a_customer": "Notiz über eine kunde", //"Kundennotiz ",

    // View show
    "show": {
        "title": "Notiz"
    },

    // View update
    "update": {
        "title": "Neue Notiz"
    },

    // View create
    "create": {
        "title": "Notiz",
        "textarea": "Notize",
        "customers": "Kunde",
    },

    // Sidebar menu
    "menu": {
        "title": "Notizen"
    },

    // Dialog
    "dialog": {
      "delete": "notiz löschen?"
    },

    // Status
    "status": {
        "title": "Status"
    },

    // Table
    "table": {
        "search": "Suchen...",
        "customer": "Kunde",
        "text": "Notizen",
        "created_at": "Erstellt",
        "updated_at": "Aktualisiert",
        "no_data_available": "Keine Notizen verfügbar ",
    },

    // Buttons
    "btn": {
        "save": "Speichern",
        "abort": "Abrechnen",
        "create": "Notiz",
        "delete": "Löschen",
    }
}
