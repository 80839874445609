import { http } from "../http_service";


/** CUSTOMERS */
export function customers(page) {
    return http().get(`/customers-gateway?page=${page}`);
}

/** CUSTOMER SHOW */
export function customerShow(id) {
    return http().get(`/customers-gateway/${id}`);
}

/** CUSTOMER SEARCH */
export function customerSearch(data) {
    return http().post(`/customers-gateway`, data);
}