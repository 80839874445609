import * as Service from '../service/service'

export default {
    namespace: true,
    state: {
        notice: {},
        notices: [],
    },
    getters: {
        notice(state) {
            return state.notice
        },
        notices(state) {
            return state.notices
        }
    },
    mutations: {
        SET_NOTICES(state, notices) {
            state.notices = notices
        },
        SET_NOTICE(state, notice) {
            state.notice = notice
        },
        SET_DELETE_NOTICE(state, id) {
            const index = state.notices.findIndex(n => n.id === id)
            state.notices.splice(index, 1)
        },
    },
    actions: {
        async notices({commit, state}, page) {
            await Service.all(page).then(response => {
                commit('SET_NOTICES', response.data.data)
            })
        },
        async lastNotices({commit, state}, page) {
            await Service.lastNotices(page).then(response => {
                commit('SET_NOTICES', response.data.data)
            })
        },
        async noticesFromCustomer({commit, state}, data) {
            await Service.noticesFromCustomer(data).then(response => {
                commit('SET_NOTICES', response.data.data)
            })
        },
        async noticeCreate({commit, state}, data) {
            await Service.create(data)
        },
        async noticeUpdate({commit, state}, data) {
            await Service.update(data)
        },
        async noticeDelete({commit, state}, id) {
            await Service.remove(id).then(() => {
                commit('SET_DELETE_NOTICE', id)
            })
        },
    }
}
