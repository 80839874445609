export default {
  "form": {
    "required": {
      "email": "E-Mail ist pflichtfeld",
      "password": "Passwort ist pflichtfeld",
      "first_name": "Vorname ist pflichtfeld",
      "last_name": "Nachname ist pflichtfeld",
      "birthday": "Geburtstag ist pflichtfeld",
      "gender": "Geschlecht ist pflichtfeld",
      "employment": "Anstellungsart ist pflichtfeld",
    },
    "auth": {
      "error": "Anmeldung mit diesen Daten fehlgeschlagen.",
    }
  }
}
