import store from '@/store'

export default function guest({next}) {
  // Если пользователь авторизирован то его перенаправляет на главную.
  if (store.getters['authenticated']) {
    return next({
      name: 'dashboard'
    });
  }

  return next();
}
