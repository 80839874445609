<template>
  <v-menu
      v-model="date_menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :disabled="is_disabled || task_status === 'completed'"
          v-model="computedDateFormatted"
          :label="label"
          hide-details
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        @input="date_menu = false"
        locale="de-de"
        first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "MXDatePickerComponent",
  props: {
    label: {
      default: '',
      required: true
    },
    update_date: {
      required: false,
      default: null
    },
    is_disabled: {
      required: false,
      default: false
    },
    task_status: {
      required: false,
      default: 'todo'
    }
  },
  data() {
    return  {
      date: this.update_date !== null ? this.update_date : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date_menu: false,
    }
  },
  created() {
    this.getDate()
  },
  watch: {
    date() {
      this.getDate()
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  methods: {
    getDate() {
      this.$emit('input', this.date)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
  }
}
</script>

<style scoped>

</style>
