export default {
    "language": "Sprache",
    "title": "Meine Aufgaben",
    "search": "Suchen ...",
    "executor": "Zuständig", // Verantwortlich
    "observer": "Beobachter",
    "customer": "Kunde",
    "customers": "Kunden",
    "file": "Datei ",
    "created_by": "Erstellt von",
    "buttons": {
        "save": "Speichern",
        "abort": "Abbrechen",
        "close": "Schließen"
    },
    "menu": {
        "title": "Meine Aufgaben",
        "all_title": "Alle aufgaben",
        "my_title": "Meine aufgaben",
    },
    "status": {
        "todo": "In der Warteschlange", // Neu
        "in_progress": "Im Prozess",
        "completed": "Erledigt", // Fertig
        "history": "Historie",
    },
    "importance": {
        "title": "Priorität",
        "important": "Wichtig",
        "urgently": "Dringend",
        "normally": "Normal",
        "last": "Zuletzt",
    },
    "create": {
        "title": "Neue Aufgabe",
        "status": "Status",
        "title_label": "Aufgaben",
        "description": "Beschreibung",
        "search_label": "Suchen...",
        "search_placeholder": "Mitarbeiter suhen...",
        "start_date": "Erstellt Datum",
        "end_date": "Fällig am",
        "file": {
            "title": "Datei hochladen",
            "file_delete": "Datei löschen ",
        },
    },
    "update": {
        "title": "Aufgabe ändern",
        "file": {
            "title": "Datei hochladen",
            "file_delete": "Datei löschen ",
        },
    },
    "tabs": {
        "description": "Beschreibung",
        "checklist": "Checkliste ",
    },
    "table": {
        "id": "ID",
        "created_by": "Erstellt von",
        "importance_status": "Priorität",
        "name": "Aufgaben",
        "date": "Datum",
        "checklist_status": "",
        "start_time": "Erstellt Datum",
        "end_time": "Fällig am",
        "status": "Status",
        "status_percent": "Prozent ",
        "executor": "Zuständig", // Verantwortlich
        "observer": "Beobachter ",
        "labels": "Etiketten",
        "filter": {
            "group_by_creator": "Nach Ersteller gruppieren",
            "group_by": "Gruppieren nach",
            "start_time": "Nach Erstellungsdatum",
            "end_time": "Nach Fällung Datum",
            "importance_status": "Nach Priorität",
            "created_by": "Nach Ersteller",
            "dont_group": "Nicht gruppieren",
        }
    },
    "checklist": {
        "add_label": "Neue Listenpunkt",
        "create_time": "um",
        "created_by": "Erstellt",
        "update_time": "um", //Geändert
        "executor": "Erledigt",
    },
    "dialog": {
        "close": {
            "title": "Das Fenster schließen? ",
            "description": "Alle von Ihnen vorgenommenen Änderungen werden nicht gespeichert.",
            "buttons": {
                "yes": "Ja",
                "no": "Nein",
            }
        },
        "delete": {
            "title": "Die Aufgabe löschen?",
            "description": "---",
            "buttons": {
                "yes": "Löschen",
                "no": "Abbrechen",
            }
        },
        "task_not_found": {
            "title": "Die Aufgabe nicht gefunden!",
            "subtitle": "oder Sie haben keine Zugriffsrechte.",
            "buttons": {
                "ok": "Ok"
            },
        },
        "template": {
            "title": "Templates!",
            "subtitle": "",
            "create": "Neu Template",
            "buttons": {
                "ok": "Ok",
                "open": "Templates"
            },
            "table": {
                "ok": "Ok",
                "title": "Name",
                "creator": "Erstellt von",
                "delete": "Die Template löschen?",
                "is_my": "Nur meine Templates",
            }
        }
    },

    "observe_tasks": {
        "menu": "Beobachteraufgaben",
        "title": "Beobachteraufgaben"
    },

    "comment": {
        "no_comments_yet": "Noch keine Kommentare",
        "tabs": {
            "title": "Kommentar"
        },
        "form": {
            "placeholder": "Kommentar"
        },
        "buttons": {
            "create": "Kommentar"
        }
    }
}
