import store from '@/store'
import env from '../env'

const permission = env.middleware.permission;

function create({next}) {
  if (!can('create')) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function read({next}) {
  if (!can('read')) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function update({next}) {
  if (!can('update')) {
    return next({
      name: 'dashboard'
    });
  }
  return next();
}

function can(prefix) {
  return store.getters.auth_permissions.find((per) => per === `${permission}-${prefix}`) !== undefined
}


export { create, read, update, can }
