<template>
  <v-app-bar app dense elevation="1" color="header_bg" dark class="">
    <v-app-bar-nav-icon class="" @click.stop="drawerLeft()"></v-app-bar-nav-icon>
    <router-link to="/">
      <v-img
          class="mr-6 d-md-none"
          max-width="96"
          src="/assets/images/logo.png"
          alt="Peepz GmbH"
      ></v-img>
    </router-link>
<!--    <v-btn-->
<!--        :ripple="false"-->
<!--        hidden-->
<!--        plain-->
<!--        class="d-md-flex"-->
<!--        active-class="mx-btn-active"-->
<!--        v-for="link in links"-->
<!--        :key="link.title"-->
<!--        :to="link.url"-->
<!--    >-->
<!--      {{ link.title }}-->
<!--    </v-btn>-->

<!--    v-if="$store.getters.report.status === 0"-->

    <v-spacer></v-spacer>

    <v-btn v-if="$store.getters.report_button && report_dialog === false" class="mx-call-btn" elevation="8" @click="report_dialog = true">
<!--      <v-icon style="color: #fff; margin-left: 3px; margin-right: 7px;" size="24">mdi-content-save-outline</v-icon>-->
      {{ $t('report.btn.report_save') }}
    </v-btn>

<!--    <v-btn elevation="8" @click="test">switch</v-btn>-->

    <v-spacer></v-spacer>
    <Date class="mt-n1 mr-8" />

    <TopUserMenu/>

    <v-btn
        elevation="0"
        class="rounded-0"
        color="default"
        style="height: 48px; margin-right: -16px;"
        @click="$store.commit('setStatusTaskDrawer', true)"
    >
<!--      <v-icon style="color: #fff;" size="20">mdi-checkbox-marked-circle-plus-outline</v-icon>-->
      <v-icon style="color: #fff;" size="20">far fa-calendar-plus</v-icon>
    </v-btn>

    <v-dialog
        v-if="report_dialog"
        style="z-index: 10000;"
        v-model="report_dialog"
        :width="dialogWidth"
        persistent

    >
      <ReportDialog @dialog_close="report_dialog = false" />
    </v-dialog>
  </v-app-bar>
</template>

<script>

import TopUserMenu from "@/components/User/TopUserMenu";
import ReportDialog from "@/components/Report/ReportDialog";
import Date from "@/components/Header/MxDate/MxFullDate.vue";


export default {
  components: {TopUserMenu, ReportDialog, Date},
  data() {
    return {
      report_dialog: false,
      links: []
    }
  },
  created() {
    this.links = this.$store.getters.menu
    history.replaceState("", "", location.href)
  },
  computed: {
    dialogWidth() {
      return window.innerWidth <= '1366' ? '90%' : '60%'
    },
  },
  methods: {
    test() {
      let status = Math.floor(Math.random() * 2)
      this.$store.dispatch('report_add', {id: 1, status: status})
    },
    drawerLeft() {
      const bool = this.$store.getters.drawer_left;
      this.$store.commit('set_drawer_left', !bool)
    },
  }
}
</script>

<style lang="scss">

.mx-call-btn {
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% { background-color: #a80505;}
  50% { background-color: #e81919;}
  100% { background-color: #a80505;}
}

</style>
