/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/company";

export default [
    {
        path: '/companies',
        name: 'companies',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/company/CompaniesView')
    },
    {
        path: '/companies/:company_id/show',
        name: 'companies.show',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/company/CompanyShowView')
    },
    {
        path: '/companies/search',
        name: 'companies.search',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/company/CompanySearchView')
    }
];