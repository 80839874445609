<template>
  <div ref="container_position" :style="`position:relative; min-height: calc(100vh - ${top}px)`">
    <slot/>
  </div>
</template>

<script>

export default {
  name: "MXContainerWrapper",
  data() {
    return {
      top: 0
    }
  },
  created() {
    setTimeout(() => {
      this.getContainerHeight()
    }, 100)
  },
  methods: {
    getContainerHeight() {
      this.top = this.$refs.container_position.getBoundingClientRect().top
    },
  }
}
</script>

<style lang="scss">

</style>
