export default {
  version: 'Version: 2.5',
  multi_company: false,
  watcher_time: 60,
  admin_message_time: 60000,
  watcher_debug: false,
  preload_time: 100,
  preload_logo: true,
  home_preload_time: 1200,
  language: 'de',
  route_mode: 'history',
  default_url: (process.env.NODE_ENV === 'production') ? 'https://employee-api.peepz.net' : 'http://peepz.api.employee.loc',
  avatar_url: (process.env.NODE_ENV === 'production') ? 'https://employees.peepz.net/storage/' : 'http://peepz.employees.loc/storage/',
  document_url: (process.env.NODE_ENV === 'production') ? 'https://documents.peepz.net/storage/' : 'http://peepz.crm.document.loc/storage/',
  document_url_api: (process.env.NODE_ENV === 'production') ? 'https://documents.peepz.net/api' : 'http://peepz.crm.document.loc/api',
}
