/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/document";


export default [
    {
        path: 'all',
        name: 'documents.documents',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/document/Documents'),
    },
    {
        path: 'create',
        name: 'document.create',
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('@/views/document/DocumentCreate'),
    },
    {
        path: ':id/edit',
        name: 'document.edit',
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('@/views/document/DocumentEdit'),
    }

];
