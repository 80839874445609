import user from './user/_lang_user'
import auth from './_lang_auth'
import error from './_lang_errors'
import company from './_lang_company'
import faq from './_lang_faq'
import middleware from './_lang_middelware'
import customer from './_lang_customer'
import report from './_lang_report'
import document from './_lang_document'

// Module
import notice from '@/module/notice/lang/de/lang'
import conversation from '@/module/conversation/lang/de/lang'
import task from '@/module/task/lang/de/lang'

export default {
    "greeting": "Hi",
    "not_found": "Nicht gefunden",
    "page_not_found": "Site nicht gefunden",
    "to_home": "Zurück",
    "user": user,
    "auth": auth,
    "error": error,
    "company": company,
    "customer": customer,
    "report": report,
    "faq": faq,
    "document": document,
    "middleware": middleware,
    "notice": notice,
    "conversation": conversation,
    "task": task,
    "copyright": '© peepz GmbH {year}, All Rights Reserved',
    "language": {
        "de": {
            "de": "Deutsch",
            "en": "English",
            "ru": "Russisch"
        },
        "en": {
            "en": "English",
            "de": "Germany",
            "ru": "Russia"
        },
        "ru": {
            "ru": "Русский",
            "de": "Немецкий",
            "en": "Английский"
        }
    },
    "countries":{
        "germany": "Deutschland",
    },
    "setting": {
        "settings": {
            "title": "Einstellungen",
            "tab_title": "Einstellungen",
            "changed": "Einstellungen wurde geändert",
            "user": {
                "identification": "Personalnummer",
                "email": "Email"
            },
            "form": {
                "first_name": 'Vorname',
                "last_name": 'Nachname',
                "language": 'Sprache',
            },
            "errors": {
                "first_name": 'Vorname kann nicht leer sein',
                "last_name": 'Nachname kann nicht leer sein',
            }
        },
        "password": {
            "title" : "Passwort ändern",
            "tab_title" : "Passwort ändern",
            "form": {
                "label": 'Passwort',
                "label_confirmation": "Passwort Bestätigung",
                "password_do_not_match": "Passwörter stimmen nicht überein!",
                "password_changed": "Das Passwort wurde geändert"
            },
        }
    },
    "tabs": {
        "companies": "Firmen",
        "employee": "Mitarbeiter",
        "address": "Standorte",
        "documents": "Dokumente",
        "faq": "FAQ",
        "cad": "Zugangsdaten",
        "notice": "Notizen",
        "conversation": "Historie",
        "tasks": "Aufgaben",
    },
    "sidebar": {
        "menu": {
            "dashboard": "Dashboard",
            "customers": "Kunden",
            "documents": "Meine Dokumente",
            "reports": "Meine Berichte",
        }
    },
    "contact_person": {
        "first_name": "Vorname",
        "last_name": "Nachname",
        "email": "Email",
        "position": "Position",
        "phone": "Telefonnummer",
    },
    "week_day": {
        "Monday": "Montag",
        "Tuesday": "Dienstag",
        "Wednesday": "Mittwoch",
        "Thursday": "Donnerstag",
        "Friday": "Freitag",
        "Saturday": "Samstag",
        "Sunday": "Sonntag",
    },
    "week_day_small": {
        "Mo": "Mo",
        "Tu": "Di",
        "We": "Mi",
        "Th": "Do",
        "Fr": "Fr",
        "Sa": "Sa",
        "Su": "So",
    },
    "mx_calender": {
        "week_day": {
            "Monday": "Montag",
            "Tuesday": "Dienstag",
            "Wednesday": "Mittwoch",
            "Thursday": "Donnerstag",
            "Friday": "Freitag",
            "Saturday": "Samstag",
            "Sunday": "Sonntag",
        },
        "week_day_small": {
            "Mo": "Mo",
            "Tu": "Di",
            "We": "Mi",
            "Th": "Do",
            "Fr": "Fr",
            "Sa": "Sa",
            "Su": "So",
        },
        "month": {
            "January":"Januar",
            "February":"Februar",
            "March":"März",
            "April":"April",
            "May":"Mai",
            "June":"Juni",
            "July":"Juli",
            "August":"August",
            "September":"September",
            "October":"Oktober",
            "November":"November",
            "December":"Dezember",
        },
        "month_num": {
            "01":"Januar",
            "02":"Februar",
            "03":"März",
            "04":"April",
            "05":"Mai",
            "06":"Juni",
            "07":"Juli",
            "08":"August",
            "09":"September",
            "10":"Oktober",
            "11":"November",
            "12":"Dezember",
        }
    }
}
