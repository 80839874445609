import store from '@/store'

function hasPermission(permission) {
  const permissions = store.getters.auth_permissions
  return permissions.find((per) => per === permission) !== undefined
}

function can(permission) {
  const permissions = store.getters.auth_permissions
  return permissions.find((per) => per === permission) !== undefined
}

function subPermission(permissions) {
  const auth_permissions = store.getters.auth_permissions
  for (let i = 0; i < permissions.length; i++) {
    for (let j = 0; j < auth_permissions.length; j++) {
      if (auth_permissions[j] === permissions[i]) {
        return true
      }
    }
  }
  return false
}

function hasRole(role) {
  const roles = store.getters.roles
  return roles.find((rol) => rol === role) !== undefined
}

export {hasPermission, can, subPermission, hasRole}