export default {
    "title": "Meine Berichte",
    "not_reports": "Es gibt noch keine Berichte",
    "btn": {
        "report_save": "Bericht speichern"
    },
    "table": {
        "id":"ID",
        "date":"Datum",
        "caller_number":"Anrufernummer (CN)", //cn
        "called_number":"Angerufene Nummer (DID)", // did
        "company_did":"Angerufene Firma (DID)", // Kompanie DID
        "company_cn":"Anrufende Firma (CN)", // Kompanie CN
        "category":"Kategorie",
        "start_time":"Startzeit",
        "end_time":"Endzeit",
        "time":"Zeit",
        "notice":"Notiz"
    },
    "search": {
        "label": "Suche...",
        "placeholder": "Suche...",
        "select": {
            "date":"Datum",
            "caller_number":"Anrufernummer (CN)", //cn
            "called_number":"Angerufene Nummer (DID)", // did
            "company_did":"Angerufene Firma (DID)", // Kompanie DID
            "company_cn":"Anrufende Firma (CN)", // Kompanie CN
            "category":"Kategorie",
        },
    }
}
