<template>
  <div class="mx-task-form" style="position: relative; min-height: 768px; ">
    <mx-preloader style="max-width: 1366px;" :loader-status="preloader"/>
    <v-container fluid class="pa-6 mx-task-form" v-if="$store.getters.getStatusTaskDrawer">
      <v-row>
      <v-col cols="12" v-if="isDisabled">
        <h3 class="mx-task-form-title">{{ $t('task.create.title') }}</h3>
        <div class="mt-5 mb-1" style="border-bottom: 1px dashed #dadada"></div>
      </v-col>

<!--  LEFT      -->
        <v-col cols="12" lg="7" style="min-height: 640px">
          <div class="mx-task-form-bg pa-7 pb-0" style="min-height: 100%;">
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
              <v-tab>{{ $t('task.tabs.description') }}</v-tab>
              <v-tab>{{ $t('task.tabs.checklist') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="mx-task-form-tabs">
              <v-tab-item>
                <v-card color="basil" flat  style="height: 520px;">
                  <v-row class="mt-5">
                    <v-col cols="12" class="d-flex align-center">
                      <v-text-field :disabled="!isCreator" v-model="form.name" :label=" $t('task.create.title_label')" hide-details outlined class="elevation" :error="errors.name!==undefined"></v-text-field>
                      <v-btn
                          style="height: 48px;margin-top: -2px;margin-left: 10px;"
                          @click="dialog_task_template = true"
                      >
                        {{ $t('task.dialog.template.buttons.open') }} <br>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" style="position: relative;">
                      <vue-editor
                          :key="12"
                          v-model="form.description"
                          :editor-toolbar="customToolbar"
                          :disabled="!isCreator"
                      />
                      <v-btn @click="dialog_task_template_create = true" style="position:absolute; top: 21px; right: 26px;" small color="primary">
                        <v-icon size="14">fas fa-plus</v-icon>
                      </v-btn>
<!--                      <v-textarea :disabled="!isCreator" hide-details height="426" outlined :label="$t('task.create.description')" v-model="form.description" :error="errors.description!==undefined"></v-textarea>-->
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card color="basil" flat >
                  <v-row class="mt-5">
                    <v-col cols="12" >
                      <TaskChecklistComponent :executors="[$store.getters.user]" :update_checklist="[]" v-if="!preloader" :creator="$store.getters.user" @getChecklistItems="getChecklistItems" />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
<!--  RIGHT      -->
        <v-col cols="12" lg="5" style="min-height: 100%">
          <div class="mx-task-form-bg pa-7 d-flex flex-column" style="min-height: 100%;">
           <div>
             <v-row class="mb-2 d-flex align-center">
<!--               <v-col cols="12" md="6">-->
<!--                 <div class="d-flex align-center" v-if="!preloader">-->
<!--                   <UserAvatarComponent  :tooltip_disabled="true" :avatar="creator.avatar" :first_name="creator.first_name" :last_name="creator.last_name"/>-->
<!--                   <div class="ml-2 d-flex flex-column justify-center mx-task-form-user">-->
<!--                     <div class="mb-n1 mx-task-form-user__title">-->
<!--                       {{ $t('task.created_by') }}-->
<!--                     </div>-->
<!--                     <div class="mb-n1 mx-task-form-user__name">-->
<!--                       <span v-if="(creator.first_name.length + creator.last_name.length) < 24">-->
<!--                         {{ creator.first_name }} {{ creator.last_name }}-->
<!--                       </span>-->
<!--                       <span v-else>-->
<!--                         {{ creator.first_name[0] }}.{{ creator.last_name }}-->
<!--                       </span>-->
<!--                     </div>-->
<!--                     <div class="mx-task-form-user__position">-->
<!--                       {{ creator.position.slice(0,27) }} <span v-if="creator.position.length > 27">...</span>-->
<!--                     </div>-->
<!--                   </div>-->
<!--                 </div>-->
<!--               </v-col>-->
               <v-col cols="12" md="6">
                 <v-select
                     :items="statuses"
                     :label="$t('task.create.status')"
                     outlined
                     v-model="form.status"
                     hide-details
                 ></v-select>
               </v-col>
               <v-col cols="12" md="6" class="d-flex align-center" style="position:relative;">
                 <v-select
                     item-value="id"
                     item-text="company_name"
                     :items="customers"
                     :label="$t('task.customer')"
                     outlined
                     v-model="form.customer_id"
                     hide-details
                     :disabled="$store.getters.getIsObserve"
                 ></v-select>
                 <v-icon
                     v-if="form.customer_id !== null"
                     @click="form.customer_id = null"
                     style="margin-left: -50px;background: #ffffff; margin-right: 0; cursor: pointer; color: #b63535; position: absolute; right: 31px;"
                     size="14"
                 >
                   fas fa-times
                 </v-icon>
               </v-col>
             </v-row>
             <div class="mb-4" style="border-bottom: 1px dashed #dadada"></div>
             <v-row class="mb-2">
               <v-col cols="12" md="6" class="mx-task-form-executor">
                 <h5 class="mb-2 mx-task-form-label">{{ $t('task.executor') }}</h5>
                 <div class="d-flex flex-wrap align-center">
                   <div class="d-flex flex-wrap align-center">
                     <div v-if="executors_selected.length === 0" class="mr-2 mx-task-form-executor__placeholder"></div>
                     <UserAvatarComponent v-for="user in executors_selected" :key="user.uid" :avatar="user.avatar" :first_name="user.first_name" :last_name="user.last_name"/>
                     <v-menu v-if="isCreator" class="mx-task-form-executor-menu" bottom left offset-y :close-on-content-click="false">
                       <template v-slot:activator="{ on, attrs }">
                         <v-btn elevation="1" color="default" style="height: 39px; min-width: 36px; padding: 0;" v-bind="attrs" v-on="on" @click="getUsers('executor')">
                           <v-icon size="12">fas fa-plus</v-icon>
                         </v-btn>
                       </template>
                       <div class="mx-task-form-executor-menu__list" v-if="sdls === 'executor'">
                         <v-list>
                           <div class="mx-task-form-executor-menu__list_top_menu">
                             <v-text-field v-model="search" hide-details solo class="mx-2 mx-task-form-executor-menu__list_top_menu_field" :label="$t('task.create.search_label')" :placeholder="$t('task.create.search_placeholder')"/>
                           </div>
                           <div class="mx-task-form-executor-menu__list_items">
                             <v-list-item v-for="(item, index) in getExecutors" :key="item.uid">
                               <v-list-item-title>
                                 <v-checkbox v-model="form.users" :label="`${item.first_name } ${item.last_name}`" :value="item.uid" @click="executorsSelectedUpdated(item.uid)" hide-details></v-checkbox>
                               </v-list-item-title>
                             </v-list-item>
                           </div>
                         </v-list>
                       </div>
                     </v-menu>
                   </div>
                 </div>
               </v-col>
               <v-col cols="12" md="6" class="mx-task-form-observer" v-if="observers_selected.length > 0 || isCreator">
                 <h5 class="mb-2 mx-task-form-label">{{ $t('task.observer') }}</h5>
                 <div class="d-flex flex-wrap align-center">
                   <div class="d-flex flex-wrap align-center">
                     <div v-if="observers_selected.length === 0" class="mr-2 mx-task-form-executor__placeholder"></div>
                     <UserAvatarComponent v-for="user in observers_selected" :key="user.uid" :avatar="user.avatar" :first_name="user.first_name" :last_name="user.last_name"/>
                     <v-menu v-if="isCreator" class="mx-task-form-executor-menu" bottom left offset-y :close-on-content-click="false">
                       <template v-slot:activator="{ on, attrs }">
                         <v-btn elevation="1" color="default" style="height: 39px; min-width: 36px; padding: 0;" v-bind="attrs" v-on="on" @click="getUsers('observer')">
                           <v-icon size="12">fas fa-plus</v-icon>
                         </v-btn>
                       </template>
                       <div class="mx-task-form-executor-menu__list" v-if="sdls === 'observer'">
                         <v-list>
                           <div class="mx-task-form-executor-menu__list_top_menu">
                             <v-text-field v-model="search" hide-details solo class="mx-2 mx-task-form-executor-menu__list_top_menu_field" :label="$t('task.create.search_label')" :placeholder="$t('task.create.search_placeholder')"/>
                           </div>
                           <div class="mx-task-form-executor-menu__list_items">
                             <v-list-item v-for="(item, index) in getObservers" :key="item.uid">
                               <v-list-item-title>
                                 <v-checkbox v-model="form.observers" :label="`${item.first_name } ${item.last_name}`" :value="item.uid" @click="observersSelectedUpdated(item.uid)" hide-details></v-checkbox>
                               </v-list-item-title>
                             </v-list-item>
                           </div>
                         </v-list>
                       </div>
                     </v-menu>
                   </div>
                 </div>
               </v-col>
             </v-row>
             <div class="mb-7" style="border-bottom: 1px dashed #dadada"></div>
             <v-row>
               <v-col cols="12" md="6">
                 <MXDatePickerComponent :is_disabled="!isCreator" v-if="!preloader" :update_date="form.start_time" :label="$t('task.create.start_date')" @input="startDate"/>
               </v-col>
               <v-col cols="12" md="6" class="d-flex">
                 <div v-if="end_time_status" class="d-flex align-center" style="width: 100%; position:relative;">
                   <MXDatePickerComponent :is_disabled="!isCreator" v-if="!preloader" :update_date="form.end_time" :label="$t('task.create.end_date')" @input="endDate"/>
                   <v-icon v-if="isCreator" @click="endTimeCheck(false)" style="margin-left: 20px; margin-right: 0; cursor: pointer; color: #b63535; position: absolute; right: 20px" size="16">fas fa-times</v-icon>
                 </div>
                 <template v-if="isCreator && !end_time_status">
                   <div style="border: 1px dashed #cecece; border-radius: 4px; width: 100%; cursor: pointer;" class="d-flex align-center justify-center" @click="endTimeCheck(true)">
                     {{ $t('task.create.end_date') }}
                   </div>
                 </template>
               </v-col>
             </v-row>
             <div class="mt-7 mb-n1" style="border-bottom: 1px dashed #dadada"></div>
             <TaskImportanceLabelsComponent :isCreator="isCreator" v-if="!preloader" :active_label="form.importance_status" @activeStatusLabel="importanceStatus" class="mb-10 mt-7"/>
             <div class="mb-7 mt-n2" style="border-bottom: 1px dashed #dadada"></div>
             <template v-if="isCreator && form.file_name === null">
               <v-row>
                 <v-col cols="12">
                   <v-file-input
                       v-model="file"
                       :label="$t('task.update.file.title')"
                       hide-details
                       outlined
                       prepend-icon=""
                       truncate-length="15"
                       prepend-inner-icon="fas fa-paperclip"
                   ></v-file-input>
                 </v-col>
               </v-row>
               <div class="my-5" style="border-bottom: 1px dashed #dadada"></div>
             </template>

             <template v-if="form.file_name !== null">
               {{ form.file_name }}
             </template>

           </div>
           <div  class="mt-auto">
             <v-row>
               <v-col md="6">
                 <v-btn
                     style="width: 100%; padding: 28px;"
                     elevation="0"
                     color="default"
                     @click="save"
                     :loading="btn_create_loading"
                     :disabled="btn_create_loading || formItChanged"
                 >
                   {{ $t('task.buttons.save') }}
                 </v-btn>
               </v-col>
               <v-col md="6" class="">
                 <v-btn
                     style="width: 100%; padding: 28px;"
                     elevation="0"
                     @click="formItChanged ? abort() : abort_dialog = true"
                     :disabled="btn_create_loading"
                 >
                   {{ $t('task.buttons.close') }}
                 </v-btn>
               </v-col>
             </v-row>
           </div>
          </div>
        </v-col>
      </v-row>
      <v-dialog
          v-model="abort_dialog"
          persistent
          max-width="440"
      >
        <v-card class="pa-3">
          <v-card-title class="text-h5 text-center">
            {{ $t('task.dialog.close.title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('task.dialog.close.description') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text outlined @click="abort">
              {{ $t('task.dialog.close.buttons.yes') }}
            </v-btn>
            <v-btn color="darken-1" text outlined @click="abort_dialog = false">
              {{ $t('task.dialog.close.buttons.no') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <v-dialog v-model="dialog_task_template" max-width="1366">
      <v-card class="pa-3" style="min-height: 680px">
        <v-card-title class="text-h6 text-center justify-center mb-4">
          {{ $t('task.dialog.template.title') }} <br>
          {{ $t('task.dialog.template.subtitle') }}
        </v-card-title>
        <v-card-text>
          <TaskTemplateListComponent v-if="dialog_task_template" :key="125" @addText="addText" @close="dialog_task_template = false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_task_template_create" max-width="1366">
      <v-card class="pa-3" style="min-height: 680px">
        <v-card-title class="text-h6  mb-4">
          {{ $t('task.dialog.template.create') }}
        </v-card-title>
        <v-card-text v-if="dialog_task_template_create">
          <TaskTemplateCreateComponent :key="14" :name="form.name" :description="form.description" @close="dialog_task_template_create = false" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MXContainerWrapper from "../MXContainerWrapper";
import UserAvatarComponent from "../UserAvatarComponent";
import MXDatePickerComponent from "../MXDatePickerComponent";
import TaskImportanceLabelsComponent from "@/module/task/components/Importance/TaskImportanceLabelsComponent";
import TaskChecklistComponent from "@/module/task/components/Task/TaskChecklistComponent";
import TaskTemplateListComponent from "@/module/task/components/Task/Template/TaskTemplateListComponent";
import TaskTemplateCreateComponent from "@/module/task/components/Task/Template/TaskTemplateCreateComponent";
import _ from "lodash";


export default {
  name: "TaskCreateDialogComponent",
  components: {
    MXContainerWrapper,
    UserAvatarComponent,
    MXDatePickerComponent,
    TaskImportanceLabelsComponent,
    TaskChecklistComponent,
    TaskTemplateListComponent,
    TaskTemplateCreateComponent
  },
  data() {
    return {
      dialog_task_template: false,
      dialog_task_template_create: false,
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ["bold", "italic", "underline"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
        [{ list: "ordered" }, { list: "bullet" }],
        [{color: []}, {background: ['', '#fc8b5d']}],
      ],
      abort_dialog: false,
      preloader: true,
      tab: null,
      file: null,
      executors_selected: [],
      observers_selected: [],
      statuses: [
        {value: 'todo', text: this.$t('task.status.todo')},
        {value: 'in_progress', text: this.$t('task.status.in_progress')},
        {value: 'completed', text: this.$t('task.status.completed')},
        // {value: 'history', text: this.$t('task.status.history')},
      ],
      executors: [],
      customers: [],
      search: '',
      sdls: 'executor',
      end_time_status: false,
      btn_create_loading: false,
      errors: [],
      creator: this.$store.getters.user,
      create_form: {},
      form: {
        users: [],
        observers: [],
        checklist: [],
        name: '',
        customer_id: null,
        file_name: null,
        status_percent: 0,
        checklist_status: null,
        status: 'todo',
        importance_status: 'normally', // 'important', 'urgently', 'normally', 'last'
        description: '',
        start_time: null,
        end_time: null,
      }
    }
  },
  created() {
    this.getCustomers()
    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  computed: {
    getUserAvatar() {
      return this.$store.getters.user.avatar !== null ? this.$store.getters.user.avatar : '/assets/images/avatar.jpg'
    },
    getExecutors() {
      return this.executors.filter(executor => {
        return (executor.last_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || executor.first_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
            && this.observers_selected.find(observe => observe.uid === executor.uid) === undefined
      })
    },
    getObservers() {
      return this.executors.filter(executor => {
        return (executor.last_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || executor.first_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
            && this.executors_selected.find(observe => observe.uid === executor.uid) === undefined
      })
    },
    isDisabled() {
      return window.innerWidth > 1366
    },
    isCreator() {
      return this.creator.id === this.$store.getters.user.id
    },
    formItChanged() {
      return _.isEqual(this.form, this.create_form)
    },
  },
  methods: {
    addText(template) {
      this.form.name = template.title
      this.form.description = template.text
    },
    getCustomers() {
      this.$store.dispatch('customers').then(() => {
        this.customers = this.$store.getters.customers.data.map(customer => {
          customer['full_name'] = `${customer.first_name} ${customer.last_name}`
          return customer
        })
      })
    },
    getChecklistItems(checklist) {
      const checklist_length = checklist.length
      const checklist_checked_length = checklist.filter(item => item.status === 1).length

      this.form.checklist_status = `${checklist_checked_length}/${checklist_length}`
      this.form.checklist = checklist
    },
    importanceStatus(status) {
      this.form.importance_status = status
    },
    endTimeCheck(bool) {
      if (bool) {
        this.end_time_status = true
      } else {
        this.end_time_status = false
        this.form.end_time = null
      }
    },
    startDate(date) {
      this.form.start_time = date;
      this.create_form = {...this.form}
    },
    endDate(date) {
      this.form.end_time = date;
    },
    executorsSelectedUpdated(uid) {
      if (this.executors_selected.find(user => user.uid === uid) === undefined) {
        const user = this.executors.find(user => user.uid === uid)
        this.executors_selected.push(user)
      } else {
        this.executors_selected = this.executors_selected.filter(user => user.uid !== uid)
      }
    },
    observersSelectedUpdated(uid) {
      if (this.observers_selected.find(user => user.uid === uid) === undefined) {
        const user = this.executors.find(user => user.uid === uid)
        this.observers_selected.push(user)
      } else {
        this.observers_selected = this.observers_selected.filter(user => user.uid !== uid)
      }
    },
    async getUsers(e) {
      this.sdls = e
      await this.$store.dispatch('users').then(() => {
        this.executors = this.$store.getters.getUsers.filter(user => {
          return user.uid !== this.$store.getters.user.uid
        })
      })
    },
    async save() {
      this.btn_create_loading = true

      if (this.form.name.length === 0) {
        this.errors.name = 'error'
        this.btn_create_loading = false
        return this.error
      }

      let formData =  new FormData()
      Object.entries(this.form).map(entry => {
        formData.set(entry[0], JSON.stringify(entry[1]))
      })

      formData.append('file', this.file)

      await this.$store.dispatch('taskCreate', formData).then(() => {
        this.$store.dispatch('myTask').then(() => {
          this.$store.commit('setStatusTaskDrawer', false)
        })
      }).catch(error => {
        this.btn_create_loading = false
        this.tab = null
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    abort() {
      this.$store.commit('setStatusTaskDrawer', false)
    }
  }
}
</script>

<style lang="scss">

.mx-task-form {
  &-bg {
    border-radius: 6px;
  }
  &-title {
    text-transform: uppercase;
  }

  &-label {
    text-transform: uppercase;
  }

  &-user {
    &__name {
      font-size: 14px;
      font-weight: 700;
    }
    &__title {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 700;
    }

    &__position {
      font-size: 11px;
    }
  }

  &-executor {
    &__placeholder {
      border: 1px dashed #cecece;
      width: 36px;
      height: 39px;
      border-radius: 4px
    }

    &-menu {
      &__list {
        position: relative;
        max-height: 450px;

        &_top_menu {
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 999;
          padding: 5px 0 20px;
        }

        &__items {
          margin-top: 100px;
        }
      }
    }
  }
  &-footer{
    height: 80px;
    position:fixed;
    bottom: 0;
    width: 100%;
  }
}


.theme--light {
  $border-color: #cecece;

  .mx-border-right-divider {
    border-right: 1px dashed rgba($border-color,0.5);
    padding-right: 30px;
  }

  .mx-task-form {
    background: #f7f7f7;
    &-bg {
      background: #ffffff;
      border: 1px solid #f5f5f5;
    }
    &-title {
      text-transform: uppercase;
      color: #333333;
    }

    &-label {
      text-transform: uppercase;
      color: #333333;
    }

    &-user {
      &__name {
        color: #333333;
      }
      &__title {
        color: #7e7e7e;
      }

      &__position {
        color: #333333;
      }
    }

    &-executor {
      &__placeholder {
        border: 1px dashed $border-color;
      }

      &-menu {
        &__list {
          &_top_menu {
            background: #fff;
          }
        }
      }
    }
    &-footer {
      background: #ffffff;
    }
  }
}

.theme--dark {
  $border-color: #666666;


  .mx-border-right-divider {
    border-right: 1px solid rgba($border-color, 0.2);;
  }

  .mx-task-form {
    background: #121212;
    &-bg {
      background: #1e1e1e;
    }
    &-title {
      text-transform: uppercase;
      color: #cbcbcb;
    }

    &-label {
      text-transform: uppercase;
      color: #cbcbcb;
    }

    &-user {
      &__name {
        color: #eeeeee;
      }

      &__title {
        color: #7e7e7e;
      }

      &__position {
        color: #cbcbcb;
      }
    }

    &-executor {
      &__placeholder {
        border: 1px dashed $border-color;
      }

      &-menu {
        &__list {
          &_top_menu {
            background: #1e1e1e;
          }
        }
      }
    }
    &-footer {
      background: #424242;
    }
    &-tabs {
      &.theme--dark.v-tabs-items, .theme--dark.v-card {
        background-color: transparent !important;
      }
    }
  }
}
</style>
