import store from '@/store'

export default function auth({next, router}) {

  if (!store.getters['authenticated']) {
    return router.push({
      name: 'login',
    })
  }

  return next();
}
