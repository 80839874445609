import axios from 'axios';

export function http() {
    return axios.create({
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export function httpFile() {
    return axios.create({
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    });
}
