import * as Service from '@/services/auth/auth_service'


export default {
    namespace: true,
    state: {
        token: null,
        auth_permissions: [],
        auth_roles: []
    },
    getters: {
        authenticated(state) {
            return state.token
        },
        // permissions(state) {
        //     return state.permissions
        // },
        // roles(state) {
        //     return state.roles
        // },
        auth_permissions(state) {
            return state.auth_permissions
        },
        auth_roles(state) {
            return state.auth_roles
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_AUTH_PERMISSIONS(state, data) {
            state.auth_permissions = data
        },
        SET_AUTH_ROLES(state, data) {
            state.auth_roles = data
        },
        SET_LANGUAGE(state, language) {
            state.language = language
        },
    },
    actions: {

        /* Login */
        async signIn({dispatch}, credentials) {
            let response = await Service.login(credentials)
            await dispatch('attempt', response.data.meta.token)
        },

        async passwordForgot({dispatch}, data) {
            await Service.passwordForgot(data)
        },

        async passwordReset({dispatch}, data) {
            await Service.passwordReset(data)
        },

        /* Logout */
        async signOut({commit, dispatch}) {
            await Service.logout().then(() => {
                dispatch('reset')
                location.replace('/')
            }).catch(error => {
                switch (error.response.status) {
                    case 401:
                        dispatch('reset')
                        location.replace('/')
                        break;
                }
            })
        },

        /* Attempt */
        async attempt({commit, state, dispatch}, token) {
            if (token) {commit('SET_TOKEN', token)}
            if (!state.token) {return;}

            try {
                await Service.me().then(response => {
                    // dispatch('companies')
                    dispatch('setup', response)
                    // Service.test(3)
                })
            } catch (error) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
            }
        },

        /* Setup */
        async setup({commit}, response) {
            commit('SET_USER', response.data)
            commit('SET_AUTH_PERMISSIONS', response.data.auth_permission)
            commit('SET_AUTH_ROLES', response.data.auth_role)
            commit('SET_LANGUAGE', response.data.language)

            await sessionStorage.setItem('username', response.data.first_name + ' ' + response.data.last_name);
            await sessionStorage.setItem('email', response.data.email);
        },

        /* Reset */
        async reset({commit}) {
            commit('SET_TOKEN', null)
        }

    }
}
