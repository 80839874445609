import * as Service from '../service/service'

export default {
    namespace: true,
    state: {
        task_drawer: false,
        is_observe: false,
        task_update_dialog: false,
        task_id: null,
        task_create_full_page: true,
        task_create_form_clear: false,
        my_tasks: [],
        task: null,
        todo_tasks: [],
        in_progress: [],
        completed: [],
        selected_task_comments: [],

        observe_todo_tasks: [],
        observe_in_progress: [],
        observe_completed: [],
        custom_task_Status: false,
    },
    getters: {
        getCustomTaskStatus(state) {
            return state.custom_task_Status
        },
        getStatusTaskDrawer(state) {
            return state.task_drawer
        },
        getIsObserve(state) {
            return state.is_observe
        },
        getStatusTaskUpdateDialog(state) {
            return state.task_update_dialog
        },
        getTaskCreateFullPage(state) {
            return state.task_create_full_page
        },
        getTaskCreateFormClear(state) {
            return state.task_create_form_clear
        },
        getTaskId(state) {
            return state.task_id
        },
        getTask(state) {
            return state.task
        },
        getMyTasks(state) {
            return state.my_tasks
        },
        getTodoTasks(state) {
            return state.todo_tasks
        },
        getInProgressTasks(state) {
            return state.in_progress
        },
        getCompletedTasks(state) {
            return state.completed
        },

        getObserveTodoTasks(state) {
            return state.observe_todo_tasks
        },
        getObserveInProgressTasks(state) {
            return state.observe_in_progress
        },
        getObserveCompletedTasks(state) {
            return state.observe_completed
        },

        getTaskComments(state) {
            return state.selected_task_comments
        },
    },
    mutations: {
        setStatusTaskDrawer(state, status) {
            state.task_drawer = status
        },
        setCustomTaskStatus(state, status) {
            state.custom_task_Status = status
        },
        setIsObserve(state, bool) {
            state.is_observe = bool
        },
        setTaskId(state, status) {
            state.task_id = status
        },
        setTask(state, status) {
            state.task = status
        },
        setStatusTaskUpdate(state, status) {
            state.task_update_dialog = status
        },
        setMyTasks(state, tasks) {
            state.my_tasks = tasks
        },
        setTodoTasks(state, tasks) {
            state.todo_tasks = tasks
        },
        setInProgressTasks(state, tasks) {
            state.in_progress = tasks
        },
        setCompletedTasks(state, tasks) {
            state.completed = tasks
        },

        setObserveTodoTasks(state, tasks) {
            state.observe_todo_tasks = tasks
        },
        setObserveInProgressTasks(state, tasks) {
            state.observe_in_progress = tasks
        },
        setObserveCompletedTasks(state, tasks) {
            state.observe_completed = tasks
        },

        setTaskComments(state, comments) {
            state.selected_task_comments = comments
        },
    },
    actions: {
        async taskCreate({commit}, data) {
            return await Service.create(data)
        },

        async taskCommentCreate({commit}, data) {
            return await Service.commentCreate(data)
        },

        async getTaskComments({commit}, id) {
            await Service.getTaskComments(id).then(response => {
                commit('setTaskComments', response.data.data)
            })
        },

        async taskUpdate({commit}, data) {
            return await Service.update(data)
        },

        async myTask({commit, dispatch}) {
            await Service.myTask().then(response => {
                commit('setIsObserve', false)
                commit('setCustomTaskStatus', false)
                dispatch('setResponseTasks',response)
            })
        },
        async customerTask({commit, dispatch},customer_id) {
            await Service.customerTask(customer_id).then(response => {
                commit('setCustomTaskStatus', true)
                commit('setIsObserve', false)
                dispatch('setResponseTasks',response)
            })
        },

        async myObserveTasks({commit, dispatch}) {
            await Service.myObserveTasks().then(response => {
                commit('setIsObserve', true)
                commit('setCustomTaskStatus', false)
                dispatch('setResponseObserverTasks',response)
            })
        },

        setResponseObserverTasks({commit}, response) {
            commit('setObserveTodoTasks', [])
            commit('setObserveInProgressTasks', [])
            commit('setObserveCompletedTasks', [])

            if (response.data.todo !== undefined) {
                commit('setObserveTodoTasks', response.data.todo)
            }

            if (response.data.in_progress !== undefined) {
                commit('setObserveInProgressTasks', response.data.in_progress)
            }

            if (response.data.completed !== undefined) {
                commit('setObserveCompletedTasks', response.data.completed)
            }
        },

        setResponseTasks({commit}, response) {
            commit('setMyTasks', response.data)
            commit('setTodoTasks', [])
            commit('setInProgressTasks', [])
            commit('setCompletedTasks', [])

            if (response.data.todo !== undefined) {
                commit('setTodoTasks', response.data.todo)
            }

            if (response.data.in_progress !== undefined) {
                commit('setInProgressTasks', response.data.in_progress)
            }

            if (response.data.completed !== undefined) {
                commit('setCompletedTasks', response.data.completed)
            }
        },

        async getTaskById({commit}, id) {
            await Service.getById(id).then(response => {
                commit('setTask', response.data.data)
            })
        },

        async deleteTaskById({commit}, id) {
            await Service.deleteTaskById(id)
        }
    }
}
