import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = true
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}

// Preloader
import Preloader from "@/components/Preloader";
Vue.component('mx-preloader', Preloader)

// Vue2Editor
import VueEditor from "vue2-editor";
Vue.use(VueEditor);


// SCSS
import './assets/scss/main.scss'

// Options
import options from "./options";
Vue.prototype.options = options;

/* Axios */
require('./store/subscriber');
axios.defaults.withCredentials = false;
axios.defaults.baseURL = options.default_url + '/api/';

// VueI18n
import {i18n} from './plugins/i18n'

store.dispatch('attempt', localStorage.getItem('token')).then(() => {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
})
