<template>
  <v-card class="pa-6 pb-8">
    <v-row>
      <v-col cols="12">
        <h2>Bericht</h2>
        <v-divider class="mb-1 mt-2"></v-divider>
      </v-col>
    </v-row>
    <v-row class="report-data">
      <v-col cols="12" md="6">
        <ul class="report-data-list">
          <li><span>Anrufernummer:</span><strong>{{ data.caller_number }}</strong></li>
          <li><span>Angerufene Nummer:</span><strong>{{ data.called_number }}</strong></li>
          <li><span>Startzeit:</span><strong>{{ data.start_time }}</strong></li>
<!--          <li><strong>Gesamtzeit:</strong><span>{{ data.time }}</span></li>-->
<!--          <li><span>Direction:</span><strong>{{ data.direction }}</strong></li>-->
        </ul>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
            :items="categories"
            v-model="form.category_id"
            label="Kategorie"
            hide-details
            item-value="id"
            item-text="name"
            outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-textarea
            outlined
            name="notice"
            label="Notiz"
            v-model="form.notice"
        ></v-textarea>

      </v-col>
    </v-row>
    <v-row>
     <v-col class="d-flex">
       <v-btn @click="report_save" color="primary" class="ml-auto mr-2">Speichern</v-btn>
       <v-btn @click="report_abort">Abbrechen</v-btn>
     </v-col>
    </v-row>
  </v-card>
</template>

<script>


export default {
  name: "ReportDialog",
  data() {
    return {
      categories: [
        {id: 1, name: 'Allgemeine frage'},
        {id: 2, name: 'Spezifische frage'},
        {id: 3, name: 'Kündigung'},
        {id: 4, name: 'Probleme'},
      ],
      data: {
        id: 1,
        caller_number: '0587452147',
        called_number: '965142445745',
        start_time: '12:30',
        end_time: '',
        time: '05:40:16',
        direction: 'Inbound',
        status: 0,
      },
      form: {
        id: null,
        category_id: 1,
        notice: '',
      }
    }
  },
  created() {
    this.getReport()
    // console.log('Hash',location.hash, );
    // console.log('Pathname',location.pathname, location.href);
    // location.hash = ""
    // history.replaceState("", "", location.pathname)
    // history.replaceState("", "", location.pathname)

    // location.replace('/companies/search')

    // location.reload();
    // Response.redirect('https://itmaxx.ru', 403)
  },
  methods: {
    current_time() {
      let d = new Date()
      let h = this.time_change(d.getHours())
      let m = this.time_change(d.getMinutes())
      let s = this.time_change(d.getSeconds())
      return h + ':' + m + ':' + s;
    },
    time_change(num) {
      return (num < 10) ? '0' + num : num
    },
    getReport() {

      let report = JSON.parse(localStorage.getItem('report'))

      this.form = report
      this.form.category_id = report.category_id === null ? 1 : report.category_id

      this.data = {
        id: report.id,
        caller_number: report.caller_number,
        called_number: report.called_number,
        direction: report.direction,
        start_time: report.start_time,
        status: report.status,
      }

    },
    report_save() {
      this.$store.dispatch('reportUpdate', this.form).then(() => {
        this.dialog_close()
      })
    },
    report_abort() {
      this.dialog_close()
    },
    dialog_close() {
      this.$emit('dialog_close', false)
    }
  }
}
</script>

<style lang="scss">
  .report-data {
    align-items: center;
    &-list {
      list-style: none;
      padding: 0 !important;
      margin: 0 !important;
      li {
        margin-bottom: -2px;
        padding: 0;
        strong {
          font-size: 14px;
          margin-left: 10px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
</style>
