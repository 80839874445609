<template>
  <v-tooltip bottom :disabled="tooltip_disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-img v-if="avatar" v-bind="attrs" v-on="on" :src="options.avatar_url + avatar" :max-height="height" :max-width="width" style="border-radius: 4px" class="mr-1 my-1"></v-img>
      <v-avatar rounded :size="avatar_size" v-else v-bind="attrs" v-on="on" :height="height" :width="width" color="primary" class="mr-1 my-1" style="cursor: default;">
        <span class="white--text">{{ initials }}</span>
      </v-avatar>
    </template>
    <span>{{ first_name }} {{ last_name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "UserAvatarComponent",
  props: {
    avatar_size: {
      type: String,
      default: '36'
    },
    height: {
      type: String,
      default: '39'
    },
    width: {
      type: String,
      default: '36'
    },
    tooltip_disabled: {
      type: Boolean,
      default: false
    },
    avatar: {
      default: null,
      required: true
    },
    first_name: {
      type: String,
      default: '',
      required: true
    },
    last_name: {
      type: String,
      default: '',
      required: true
    },
    icon: {
      type: String,
      default: 'mdi-account-circle',
      required: false
    }
  },
  computed: {
    initials() {
      return this.first_name[0].toUpperCase() + this.last_name[0].toUpperCase()
    }
  }
}
</script>
