import * as Service from '@/services/settings/settings_service'

export default {
    namespace: true,
    state: {
        user: {},
        users: []
    },
    getters: {
        user(state) {
            return state.user
        },
        getUsers(state) {
            return state.users
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_USERS(state, users) {
            state.users = users
        }
    },
    actions: {
        /* User edit */
        async userEdit({commit}, data) {
            await Service.userUpdate(data, data.user_id).then(response => {
                commit('SET_USER', response.data.data)
                commit('SET_LANGUAGE', response.data.data.language)
            })
        },
        /* Users */
        async users({commit}) {
            await Service.users().then(response => {
                commit('SET_USERS', response.data.data)
            })
        },
    }
}