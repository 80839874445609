<template>
  <v-list class="mx-list">
    <div v-for="(link, i) in menu">
      <v-list-item active-class="default" v-if="!link.subLinks && link.can" :key="link.title" :to="link.url">
        <v-list-item-action v-if="link.icon_status">
          <v-icon class="ml-2" size="18">{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title class="ml-n2" v-text="link.title" />
      </v-list-item>
      <v-list-group v-if="link.subLinks && link.can" :prepend-icon="link.icon" :value="subIsActive(link.url)">
        <template v-slot:activator>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </template>
        <v-list-item v-for="sublink in link.subLinks" :key="sublink.title" :to="sublink.url" link>
          <v-list-item-icon v-if="sublink.icon_status">
            <v-icon v-text="sublink.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="sublink.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>

import {can} from "@/middleware/access_middleware";

export default {
  props: {
    links: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      menu: [
        {
          title: this.$t('sidebar.menu.dashboard'),
          // icon: 'mdi-view-dashboard',
          icon: 'far fa-chart-bar',
          icon_status: true,
          url: '/dashboard',
          can: can('dashboard-show')

        },
        {
          title: this.$t('sidebar.menu.customers'),
          // icon: 'mdi-account',
          icon: 'far fa-user',
          icon_status: true,
          url: '/companies',
          can: can('customer-show')
        },
        {
          title: this.$t('sidebar.menu.documents'),
          // icon: 'mdi-folder-outline',
          icon: 'fa-folder',
          url: '/documents',
          icon_status: true,
          can: can('document-show')
        },
        {
          title: this.$t('sidebar.menu.reports'),
          // icon: 'mdi-file-document-outline',
          icon: 'far fa-file-alt',
          icon_status: true,
          url: '/reports',
          can: can('report-show')
        },
        {
          title: this.$t('notice.menu.title'),
          // icon: 'mdi-note-outline',
          icon: 'far fa-sticky-note',
          icon_status: true,
          url: '/notices',
          can: can('notice-read')
        },
        {
          title: this.$t('task.menu.title'),
          // icon: 'mdi-calendar-check-outline',
          icon: 'far fa-calendar-check',
          icon_status: true,
          url: '/tasks',
          can: can('task-read')
        },
        {
          title: this.$t('task.observe_tasks.menu'),
          icon: 'fas fa-calendar-check',
          icon_status: true,
          url: '/observe/tasks',
          can: can('task-read')
        },
      ]
    }
  },
  methods: {
    subIsActive(str) {
      const paths = Array.isArray(str) ? str : [str];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0;
      });
    }
  }
}
</script>

<style>
.mx-list.v-list .v-list-item--active {
  color: #fc8b5d;
}
</style>