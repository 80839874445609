<template>
  <v-navigation-drawer app dark  v-model="drawer" style="z-index: 9999;" mobile-breakpoint="960">

    <div style="height: 100%;" class="d-flex flex-column">
        <div class="pt-7 ml-n4">
          <v-img class="mx-auto mb-7" src="/assets/images/logo.png" max-width="100" />
        </div>

      <v-divider class="mb-3"></v-divider>

      <ListGroup :links="links" />

      <v-list-item dark tag="a" @click="logout" class="error mt-auto mb-0" style="flex: inherit">
        <v-list-item-action>
          <v-icon class="mx-top-menu-action">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('auth.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

  </v-navigation-drawer>
</template>

<script>
import ListGroup from "@/components/ListGroup";
import TopUserMenu from "@/components/User/TopUserMenu";

export default {
  components: {ListGroup, TopUserMenu},
  data() {
    return {
      items: [],
      user: {},
      links: []
    }
  },
  created() {
    this.links = this.$store.getters.menu
    this.user = this.$store.getters.user
    if (window.innerWidth <= 959) {
      this.$store.commit('set_drawer_left', false)
    }
  },
  computed: {
    drawer: {
      get () {
        return this.$store.getters.drawer_left
      },
      set (val) {
        this.$store.commit('set_drawer_left', val)
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut')
    }
  }
}
</script>
