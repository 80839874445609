/* Middleware */
import auth from '@/middleware/auth';
import {show, create, update} from "@/middleware/permissions/report";

export default [
    {
        path: '/reports',
        name: 'reports',
        meta: {
            layout: 'main',
            middleware: [auth, show]
        },
        component: () => import('@/views/report/ReportsView')
    }
];