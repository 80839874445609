<template>
  <v-row no-gutters>
    <v-col cols="12">
      <h5 class="mb-2" style="text-transform:uppercase;">{{ $t('task.importance.title') }}</h5>
    </v-col>
    <v-col cols="12" lg="3" md="6" v-for="label in getLabels" :key="label">
      <TaskImportanceLabelComponent :active_class="a_label === label" :label="label" @click.native="activeLabel(label)" />
    </v-col>
  </v-row>
</template>

<script>
import TaskImportanceLabelComponent from "@/module/task/components/Importance/TaskImportanceLabelComponent";

export default {
  name: "TaskLabelsStatusComponent",
  components: {
    TaskImportanceLabelComponent
  },
  props: {
    active_label: {
      type: String,
      default: 'normally',
    },
    isCreator: {
      type: Boolean,
      default: true,
      required: false
    },
    task_status: {
      required: false,
      default: 'todo'
    }
  },
  data() {
    return {
      // status_labels: this.isCreator ? ['important', 'urgently', 'normally', 'last'] : [this.active_label],
      a_label: this.active_label
    }
  },
  computed: {
    getLabels() {
      if (!this.isCreator || this.task_status === 'completed') {
        return [this.active_label]
      } else {
        return ['important', 'urgently', 'normally', 'last']
      }
    }
  },
  methods: {
    activeLabel(label) {
      this.a_label = label
      this.$emit('activeStatusLabel', label)
    }
  }
}
</script>

<style scoped>

</style>