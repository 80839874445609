import auth from '@/middleware/auth';
import {create, read, update} from "../middleware/index";
import env from '../env'

const path = env.route.path;
const name = env.route.name;

export default [
    {
        path: `/${path}`,
        name: name,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/MainView')
    },
    {
        path: `/${path}/create`,
        name: `${path}.create`,
        meta: {
            layout: 'main',
            middleware: [auth, create]
        },
        component: () => import('../views/CreateView')
    },
    {
        path: `/${path}/:id/show`,
        name: `${path}.show`,
        meta: {
            layout: 'main',
            middleware: [auth, read]
        },
        component: () => import('../views/ShowView')
    },
    {
        path: `/${path}/:id/edit`,
        name: `${path}.edit`,
        meta: {
            layout: 'main',
            middleware: [auth, update]
        },
        component: () => import('../views/EditView')
    },

    {
        path: `/observe/${path}`,
        name: name,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/ObserveTasksView')
    },
];