<template>
  <transition name="loader-fade">
    <div :style="`z-index: ${zIndex} ;`" v-if="loaderStatus" class="mx-loader" :class="{'mx-loader__load' : loaderStatus}">
      <img v-if="logo" :src="`/assets/images/${dark === true ? 'logo' : 'logo-black'}.png`" alt="preloader">
      <img v-if="spiner" style="max-width: 160px; margin-left: 44px;" :src="`/assets/images/${dark === true ? 'spiner-w' : 'spiner-b'}.gif`" alt="preloader">
    </div>
  </transition>
</template>

<script>
  export default {
    name: "Preloader",
    props: {
      loaderStatus: {
        type: Boolean,
        default: true,
        required: true,
      },
      zIndex: {
        type: Number,
        default: 8
      },
      logo: {
        type: Boolean,
        default: true
      },
      spiner: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        dark: false
      }
    },
    created() {
      if (this.$vuetify.theme.dark) {
        this.dark = true;
      }
    }
  }
</script>