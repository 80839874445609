import * as Service from '@/services/company/company_service.js'

export default {
    namespace: true,
    state: {
        company: {},
        companies: [],
        company_addresses: [],
        contact_persons: [],
    },
    getters: {
        companies(state) {
            return state.companies
        },
        company(state) {
            return state.company
        },
        company_addresses(state) {
            return state.company_addresses
        },
        contact_persons(state) {
            return state.contact_persons
        }
    },
    mutations: {
        SET_COMPANIES(state, companies) {
            state.companies = companies
        },
        SET_COMPANY(state, company) {
            state.company = company
        },
        SET_COMPANY_ADDRESSES(state, company_addresses) {
            state.company_addresses = company_addresses
        },
        SET_CONTACT_PERSONS(state, contact_persons) {
            state.contact_persons = contact_persons
        },
        SET_QUESTIONS(state, data) {
            state.company.faq.questions = data;
        }
    },
    actions: {
        /* Companies */
        async companies({commit, state}, page) {
            // if (state.companies.length === 0) {
                await Service.companies(page).then(response => {
                    commit('SET_COMPANIES', response.data)
                })
            // }
        },

        /* Company */
        async companyFromCustomer({commit, state}, customer_id) {
            await Service.companyFromCustomer(customer_id).then(response => {
                commit('SET_COMPANY', response.data)
            })
        },

        /* Company Show */
        async companyShow({commit, state}, company_id) {
            await Service.companyShow(company_id).then(response => {
                commit('SET_COMPANY', response.data.data)
            })
        },

        /* Companies search */
        async companySearch({commit, state}, data) {
            await Service.companySearch(data).then(response => {
                commit('SET_COMPANIES', response.data)
            })
        },

        /* Companies search From Call */
        async companySearchFromCall({commit, dispatch, state}, data) {
            // history.replaceState("", "", location.pathname)
            history.replaceState("", "", '/companies')
            history.pushState(null, document.title, location.href)

            // http://localhost:8081/companies/search/?cn=00491723173367&did=4962053794445%3ApeepzWarteschleife&ns=83
            // http://localhost:8081/companies/search/?cn=00491723173367&did=4962053619180%3ApeepzWarteschleife&ns=83

            // http://localhost:8081/companies/search/?cn=004962053794443&did=021154559183%3ApeepzWarteschleife&ns=83
            // http://localhost:8081/companies/search/?cn=004962053794443&did= 4962053619180%3ApeepzWarteschleife&ns=83
            // http://localhost:8081/companies/search/?cn=004962053794443&did= 021154559183%3ApeepzWarteschleife&ns=83

            await Service.companySearchFromCall(data).then(response => {
                commit('SET_COMPANY', response.data.data)
                dispatch('report_add', response.data.data.report.data)
            }).catch((error) => {
                switch (error.response.status) {
                    case 402:
                        commit('SET_COMPANY', {})
                        dispatch('report_add', error.response.data.report.data)
                        break;
                }
            })

        },

        /* FAQ Questions */
        async questionPerFaqId({commit}, faq_id) {
            await Service.questionPerFaqId(faq_id).then(response => {
                commit('SET_QUESTIONS', response.data.data)
            })
        }
    }
}
