export default {
    "language": "Sprache",
    "title_menu": "Historie", //Conversation
    "title": "Historie", //Conversation
    "title_last": "Neue Historie", //Conversation
    "employee": "Mitarbeiter",
    "contact_person": "Ansprechpartner",
    "not_histories": "Es gibt noch keine historie",
    "tags": {
        "show": "Tags",
        "title": "Tags",
        "display_text": "Display text",
        "code_text": "Code text",
        "color": "Farbe",
        "create": "Neue Tag",
        "reload": "Aktualisieren",
    },
    "form": {
        "employee": "Mitarbeiter",
        "description": "Beschreibung",
        "contact_person": "Ansprechpartner",
        "tags": "Tags",
        "tags_placeholder": "Tag auswählen",
    },

    // View show
    "show": {
        "title": "Notiz"
    },

    // View update
    "update": {
        "title": "Neue Notiz"
    },

    // View create
    "create": {
        "title": "Notiz",
        "textarea": "Notize",
        "customers": "Kunde",
    },

    // Sidebar menu
    "menu": {
        "title": "Notizen"
    },

    // Dialog
    "dialog": {
      "delete": "notiz löschen?"
    },

    // Status
    "status": {
        "title": "Status"
    },

    // Table
    "table": {
        "company": "Firmaname",
        "employee": "Mitarbeiter",
        "description": "Beschreibung",
        "contact_person": "Ansprechpartner",
        "tag": "Tag",
        "tage": "Keine Notizen verfügbar",
        "date": "Date",
    },

    // Buttons
    "btn": {
        "save": "Speichern",
        "abort": "Abrechnen",
        "create": "Notiz",
        "delete": "Löschen",
    }
}
