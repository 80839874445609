import store from '@/store'

export function reportWatcher() {
    function activity() {
        if (null !== localStorage.getItem('report')) {
            const report = JSON.parse(localStorage.getItem('report'))
            if (report.status === 0) {
                store.commit('SET_REPORT_BUTTON', true)
            } else {
                store.commit('SET_REPORT_BUTTON', false)
            }
        }
    }

    let activityEvents = ['click', 'touchstart', 'mousemove', 'load'];
    activityEvents.forEach((eventName) => {
        document.addEventListener(eventName, activity, true);
    });
}
