export default {
  "first_name": "Firstname",
  "last_name": "Lastname",
  "password": "Passwort",
  "email": "Email",
  "add_user": "New User",
  "status": {
    "active": "Active",
    "not_active": "Inactive",
  },
  "employment": {
    "intern": "Intern",
    "extern": "Extern",
    "client": "Client",
  }
}
