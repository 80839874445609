<template>
  <div>
    <v-text-field
        v-model="form.title"
        :label=" $t('task.create.title_label')"
        solo
        class="elevation"
    ></v-text-field>
    <vue-editor
        :key="13"
        v-model="form.text"
        :editor-toolbar="customToolbar"
    />
    <div style="height: 30px;"></div>
    <div class="mt-auto">
      <v-row>
        <v-col md="4" lg="3">
          <v-btn
              style="width: 100%; padding: 28px;"
              elevation="0"
              color="default"
              @click="save"
              :disabled="form.title.length < 3 || form.text.length < 10"
          >
            {{ $t('task.buttons.save') }}
          </v-btn>
        </v-col>
        <v-col md="4" lg="3">
          <v-btn
              style="width: 100%; padding: 28px;"
              elevation="0"
              @click="close"
          >
            {{ $t('task.buttons.close') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import * as Service from '../../../service/service'

export default {
  name: "TaskTemplateCreateComponent",
  props: {
    name: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      default: '',
      required: true
    },
  },
  data() {
    return {
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        ["bold", "italic", "underline"],
        [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
        [{ list: "ordered" }, { list: "bullet" }],
        [{color: []}, {background: ['', '#fc8b5d']}],
      ],
      form: {
        title: this.name,
        text: this.description,
        status: 1
      },
      btn_disabled: true
    }
  },
  methods: {
    save() {
      Service.TaskTemplatesCreate(this.form).then(() => {
        this.close()
      })
    },
    close() {
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped>

</style>