import * as Service from '@/services/company/report_service.js'
import {report} from "@/services/company/report_service.js";

export default {
    namespace: true,
    state: {
        button_report: false,
        report: {
            id: null,
            status: 1
        },
        reports_from_employee: [],
        companies_for_reports: []
    },
    getters: {
        report(state) {
            return state.report
        },
        reports_from_employee(state) {
            return state.reports_from_employee
        },
        companies_for_reports(state) {
            return state.companies_for_reports
        },
        report_button(state) {
            return state.button_report
        }
    },
    mutations: {
        SET_REPORT(state, report) {
            state.report = report
        },
        SET_REPORTS_FROM_EMPLOYEE(state, reports) {
            state.reports_from_employee = reports
        },
        SET_COMPANIES_FOR_REPORTS(state, companies) {
            state.companies_for_reports = companies
        },
        SET_REPORT_STATUS(state, status) {
            state.report.status = status
        },
        SET_REPORT_BUTTON(state, status) {
            state.button_report = status
        },
    },
    actions: {
        /* Report */
        async report({commit, state}, id) {
            await Service.report(id).then(response => {
                commit('SET_REPORT', response.data)
            })
        },

        /* Report */
        async reportUpdate({commit, dispatch, state}, data) {
            await Service.reportUpdate(data).then(response => {
                commit('SET_REPORT', response.data.data)
                dispatch('report_add', response.data.data)
            })
        },

        /* Reports from Employee */
        async reportFromEmployee({commit, dispatch, state}, employe_id) {
            await Service.reportFromEmployee(employe_id).then(response => {
                // console.log(JSON.parse(response.data.reports)['2021']['06']);
                // commit('SET_REPORTS_FROM_EMPLOYEE', JSON.parse(response.data.reports)['2021']['06'])
                commit('SET_REPORTS_FROM_EMPLOYEE', JSON.parse(response.data.reports))
                commit('SET_COMPANIES_FOR_REPORTS', response.data.companies)
            })
        },

        report_add({commit, dispatch}, report) {
            if (report.status === null) {
                report.status = 0
            }

            localStorage.setItem('report', JSON.stringify(report))
            commit('SET_REPORT', report)

            if (report.status === 0) {
                commit('SET_REPORT_BUTTON', true)
            } else {
                commit('SET_REPORT_BUTTON', false)
            }
        },

        reports_add({commit, dispatch}, report) {
            if (localStorage.getItem('report')) {
                let local_report = JSON.parse(localStorage.getItem('report'))
                if (report.id === local_report.id) {
                    localStorage.setItem('report', JSON.stringify(report))
                    commit('SET_REPORT', report)
                } else {
                    let reports = []
                    if (localStorage.getItem('reports')) {
                        reports = JSON.parse(localStorage.getItem('reports'))
                        if (!reports.find(rep => rep.id === local_report.id)) {
                            reports.push(local_report)
                            localStorage.setItem('reports', JSON.stringify(reports))
                        }
                    } else {
                        reports.push(local_report)
                        localStorage.setItem('reports', JSON.stringify(reports))
                    }
                    localStorage.setItem('report', JSON.stringify(report))
                    commit('SET_REPORT', report)
                }
            }
        }
    }
}
