export default {
  "login": "Einlogen",
  "logout": "Auslogen",
  "form": {
    "btn": {
      "login": "Einlogen",
      "logout": "Auslogen",
    },
    "login": {
      "label": {
        "email": "Email",
        "password": "Passwort"
      },
      "required": {
        "email": "E-Mail ist pflichtfeld",
        "password": "Passwort ist pflichtfeld",
      },
      // "error": "Anmeldung mit diesen Daten fehlgeschlagen."
      "error": "E-mail oder Passwort falsch!"
    },
    "password": {
      "forgot": {
        "title": "Passwort vergessen?",
        "subtitle": "Kein Problem, das passiert schon mal.",
        "email": "Email",
        "btn": {
          "forgot": "Passwort vergessen?",
          "send": "zurücksetzen",
          "login": "Zurück zur Anmeldung",
        }
      },
      "email": {
        "title": "Wir haben Ihnen einen Link zum Zurücksetzen <br> Ihres Passworts gesendet.",
        "text": "Wir senden Anweisungen an die angegebene E-Mail-Adresse, wenn sie mit einem Konto verknüpft ist."
      },
      "reset": {
        "title": "Passwort zurücksetzen",
        "token": "Token",
        "new_password": "Neues Passwort",
        "new_password_confirmation": "Neues Passwort bestätigen",
        "btn": {
          "reset": "Absenden",
          "login": "Zurück zur Anmeldung",
        },
        "error": "Token oder Passwort ist falsch!"
      },
    }

  }
}
