import { http } from "../http_service";


/** Report */
export function report(id) {
    return http().get(`/report/${id}`);
}

/** Report update */
export function reportUpdate(data) {
    return http().put(`/companies/report`, data);
}

/** Report from Employee */
export function reportFromEmployee(employee_id) {
    return http().get(`/reports/${employee_id}/employee`);
}
