const module_name = 'task'
const singular    = 'task'
const plural      = 'tasks'

export default {
    "base_url": "",
    "module_path": `@/module/${module_name}`,
    "view": {
        "name": singular[0].toUpperCase() + singular.slice(1),
    },
    "route": {
        "path": plural,
        "name": singular
    },
    "middleware": {
        "permission": singular
    }
}
