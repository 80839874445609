export default {
  "first_name": "Vorname",
  "last_name": "Nachname",
  "password": "Passwort",
  "email": "Email",
  "identification": "Personalnummer",
  "status": {
    "active": "Aktiv",
    "not_active": "Inaktiv",
  },
  "employment": {
    "intern": "Intern",
    "extern": "Extern",
    "client": "Kunde",
  },
  "gender": {
    "title": "Geschlecht",
    "female": "Weiblich",
    "male": "Männlich",
    "divers": "Divers",
    "indefinite": "Unbestimmt"
  }
}
