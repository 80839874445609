<template>
  <div>
    <mx-preloader style="max-width: 1366px;" :loader-status="preloader"/>

    <v-row>
      <v-col class="mx-task-template-table-default" cols="12" md="6">
        <div style="margin-top: -10px; margin-bottom: 10px;">
          <input
              type="text"
              style="height: 36px; border: 1px solid #cecece; border-radius: 3px; width: 100%;padding: 0 16px;"
              placeholder="Suchen ..."
              v-model="search_template"
          >
        </div>
        <div class="mx-task-template-table-default__header">
         <div class="mx-task-template-table-default__header-menu">
           <v-row>
             <v-col cols="12" md="6">{{ $t('task.dialog.template.table.title') }}</v-col>
             <v-col cols="12" md="6">{{ $t('task.dialog.template.table.creator') }}</v-col>
           </v-row>
         </div>
        </div>

        <div style="margin-top: 20px;">
          <v-row
              class="mx-task-template-table-default__row"
              :class="{'active': template_show_id === item.id}"
              v-for="item in templatesFilter" :key="item.id"
              @click="showTemplate(item)"
          >
            <v-col cols="12" md="6">
              <strong>{{ item.title }}</strong>
            </v-col>
            <v-col cols="12" md="6" style="display: flex; align-items: center; justify-content: space-between">
              <strong>{{ item.creator.first_name }} {{ item.creator.last_name }}</strong>

              <div v-if="isCreator(item)" style="display: flex;">
                <div @click.prevent="taskTemplateUpdateDialog(item)">
                  <v-icon
                      size="14"
                      style="color: #4b4b4b; margin-right: 20px;"
                      class="mx-task-template-table-default__row_icon"
                  >fas fa-edit</v-icon>
                </div>

                <div @click.prevent="taskTemplateDeleteDialog(item)">
                  <v-icon
                      size="14"
                      class="mx-task-template-table-default__row_icon"
                  >fas fa-trash</v-icon>
                </div>
              </div>

            </v-col>
          </v-row>
        </div>

      </v-col>
      <v-col class="mx-task-template-table-default mx-task-template-table-default__display" cols="12" md="6">
        <div v-html="template_show.text"></div>
      </v-col>
    </v-row>


    <div style="height: 30px;"></div>
    <div class="mt-auto">
      <v-row>
        <v-col md="6">
          <v-checkbox v-model="is_my" :label="$t('task.dialog.template.table.is_my')"></v-checkbox>
        </v-col>

        <v-col md="3">
          <v-btn
              style="width: 100%; padding: 28px;"
              elevation="0"
              @click="addText(template_show)"
          >
            {{ $t('task.dialog.template.table.ok') }}
          </v-btn>
        </v-col>
        <v-col md="3">
          <v-btn
              style="width: 100%; padding: 28px;"
              elevation="0"
              @click="close"
          >
            {{ $t('task.buttons.close') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>


    <v-dialog
        v-model="delete_dialog"
        persistent
        max-width="440"
    >
      <v-card class="pa-3">
        <v-card-title class="text-h5 text-center">
          {{ $t('task.dialog.template.table.delete') }}
        </v-card-title>
        <v-card-text>
          {{ $t('task.dialog.delete.description') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="btn_delete_loading" :loading="btn_delete_loading" color="darken-1" text outlined @click="taskTemplateDelete">
            {{ $t('task.dialog.delete.buttons.yes') }}
          </v-btn>
          <v-btn :disabled="btn_delete_loading"  color="darken-1" text outlined @click="delete_dialog = false">
            {{ $t('task.dialog.delete.buttons.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_task_template_update" max-width="1366">
      <v-card class="pa-3" style="min-height: 680px">
        <v-card-title class="text-h6  mb-4">
          {{ $t('task.dialog.template.create') }}
        </v-card-title>
        <v-card-text v-if="dialog_task_template_update">
          <TaskTemplateUpdateComponent
              :key="12"
              :template_id="template_update_id"
              @close="taskTemplateUpdateDialogClose"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>

import * as Service from '../../../service/service'
import TaskTemplateUpdateComponent from "./TaskTemplateUpdateComponent";

export default {
  name: "TaskTemplateListComponent",
  components: {TaskTemplateUpdateComponent},
  data() {
    return {
      templates: [],
      templates1: [
        {
          "id": 1,
          "title": "Test Template 222",
          "text": `<p>Добавить коментарии, чтобы наблюдатели многли остовлять их так как другие возможности им недоступны кроме как просматривать задания.

<strong>и конечно создатель задания и исполнители могут остовлять коментарии.</strong></p><p><br></p><ol><li>Первое</li><li>Второе</li><li>Третье</li></ol>`,
          "status": 1,
          "creator": {
            "id": 1,
            "uid": 1,
            "first_name": "Michelle",
            "last_name": "Beinhauer",
            "avatar": "/i/e/a/0/1KxWWx1.jpg",
            "position": "CEO"
          }
        },
        {
          "id": 2,
          "title": "Test Template 2 Update",
          "text": "Will be distracted by the readable content of a page. 2 updated",
          "status": 1,
          "creator": {
            "id": 1,
            "uid": 1,
            "first_name": "Michelle",
            "last_name": "Beinhauer",
            "avatar": "/i/e/a/0/1KxWWx1.jpg",
            "position": "CEO"
          }
        },
        {
          "id": 3,
          "title": "Test Template 3 Update",
          "text": "Will be distracted by the readable content of a page. sadfsaasd",
          "status": 1,
          "creator": {
            "id": 1,
            "uid": 1,
            "first_name": "Michelle",
            "last_name": "Beinhauer",
            "avatar": "/i/e/a/0/1KxWWx1.jpg",
            "position": "CEO"
          }
        },
        {
          "id": 5,
          "title": "Test Template",
          "text": "Will be distracted by the readable content of a page.",
          "status": 1,
          "creator": {
            "id": 1,
            "uid": 1,
            "first_name": "Michelle",
            "last_name": "Beinhauer",
            "avatar": "/i/e/a/0/1KxWWx1.jpg",
            "position": "CEO"
          }
        }
      ],
      preloader: true,
      template_show: {},
      template_show_id: null,
      template_delete_id: null,
      delete_dialog: false,
      btn_delete_loading: false,
      is_my: true,
      search_template: '',
      dialog_task_template_update: false,
      template_update_id: null,
    }
  },
  created() {
    this.getTemplates()
  },
  computed: {
    templatesFilter() {
      if (this.is_my) {
        return this.templates.filter(temp =>
            temp.creator.uid === this.$store.getters.user.uid
            &&
            temp.title.toLowerCase().indexOf(this.search_template.toLowerCase()) !== -1
        )
      } else {
        return this.templates.filter(temp => temp.title.toLowerCase().indexOf(this.search_template.toLowerCase()) !== -1)
      }
    }
  },
  methods: {
    isCreator(item) {
      return this.$store.getters.user.uid === item.creator.uid
    },
    showTemplate(item) {
      this.template_show = item
      this.template_show_id = item.id
    },
    addText(item) {
      this.$emit('addText', item)
      this.close()
    },
    close() {
      this.template_show = {}
      this.template_show_id = null
      this.$emit('close', false)
    },
    getTemplates() {
      Service.getTaskTemplates().then(response => {
        this.templates = response.data.data
        this.preloader = false
      })
    },
    taskTemplateUpdateDialog(item) {
      this.template_update_id = item.id
      this.dialog_task_template_update = true
    },
    taskTemplateUpdateDialogClose() {
      this.template_show = {}
      this.template_show_id = null
      this.getTemplates()
      this.template_update_id = null
      this.dialog_task_template_update = false
    },
    taskTemplateDeleteDialog(item) {
      this.template_delete_id = item.id
      this.delete_dialog = true
    },
    taskTemplateDelete() {
      this.btn_delete_loading = true
      Service.TaskTemplatesDelete(this.template_delete_id).then(() => {
        this.getTemplates()
        setTimeout(() => {
          this.template_delete_id = null
          this.delete_dialog = false
          this.btn_delete_loading = false
        }, 500)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mx-task-template-table-default {
  //background: red;
  height: 630px;
  overflow-x: auto;
  position: relative;

  &__header {
    position: sticky;
    top: -20px;
    width: 100%;
    background: #ffffff;
    padding-top: 10px;
  }
  &__row {
    cursor: pointer;
    margin-bottom: 0;
    &:hover, &.active {
      border-radius: 7px 0 0 7px;
      background: #f6f6f6;
      i {
        opacity: 1;
      }
    }
    &:hover {

    }
    &.active {
      background: #f1f1f1;
    }
    &_icon {
      margin-right: 10px;
      color: red;
      opacity: 0;
    }
  }

  &__display {
    overflow: auto;
    background: #f1f1f1;
    padding: 30px;
    border-radius: 7px;
  }
}
</style>
