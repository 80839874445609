import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import System from "./system_store";
import Auth from './auth/auth_store'
import User from './user/user_store'
import Customers from './customer/customer_store'
import Companies from './company/company_store'
import Report from './company/report_store'
import Documents from './document/document_store'

// Module
import Notice from '@/module/notice/store/store'
import Conversation from '@/module/conversation/store/store'
import Task from '@/module/task/store/store'

export default new Vuex.Store({
  modules: {
    System,
    Auth,
    User,
    Customers,
    Companies,
    Report,
    Documents,
    Notice,
    Conversation,
    Task
  },
  state: {},
  mutations: {},
  actions: {}
})
