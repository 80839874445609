import { http } from "./http_service";

export function all(page) {
    return http().get(`/notices?page=${page}`);
}

export function lastNotices() {
    return http().get(`/notices/last-notices`);
}

export function noticesFromCustomer(data) {
    return http().get(`/notices/from-customer/${data.customer_id}?page=${data.page}`);
}

export function create(data) {
    return http().post('/notices', data)
}

export function update(data) {
    return http().put(`/notices/${data.id}`, data)
}

export function remove(id) {
    return http().delete(`/notices/${id}`)
}