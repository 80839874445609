/* Middleware */
import auth from '@/middleware/auth';

export default [
    {
        path: '/users',
        name: 'users',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/user/UsersView')
    },
    {
        path: '/users/create',
        name: 'users.create',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/user/UserCreateView')
    },
    {
        path: '/users/:id',
        name: 'users.show',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/user/UserShowView')
    },
    {
        path: '/users/:id/edit',
        name: 'users.edit',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/user/UserEditView')
    },
];