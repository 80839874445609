/* Middleware */
import auth from '@/middleware/auth';

export default [
    {
        path: '/customers',
        name: 'customers',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/customer/CustomersView')
    },
    {
        path: '/customers/:id',
        name: 'customers.show',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/customer/CustomerShowView')
    }
];