<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>

import EmptyLayout from "./layouts/EmptyLayout";
import MainLayout from "./layouts/MainLayout";
import PublicLayout from "./layouts/PublicLayout";
import AuthLayout from "./layouts/AuthLayout";

export default {
  components: {
    EmptyLayout, MainLayout, PublicLayout, AuthLayout
  },
  data() {
    return {
      goDark: false,
    }
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    }
  },
  created() {
    // this.$vuetify.theme.dark = true
  }
};
</script>
