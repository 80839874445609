<template>
  <div
      :style="`background-color: ${lables[label]}; color: #222222`"
      class="d-flex align-center justify-center mx-task-label"
      :class="{'active': active_class}"
  >
    {{ $t(`task.importance.${label}`) }}
  </div>
</template>

<script>
export default {
  name: "TaskLabelStatusComponent",
  props: {
    label: {
      type: String,
      required: true
    },
    active_class: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      lables: {
        important: '#fd594a',
        // important: '#ff3d57',
        urgently: '#fdbf5e',
        // urgently: '#22cce2',
        normally: '#09b66d',
        last: '#e3e3e3',
      }
    }
  },
  created() {
  },
  methods: {}
}
</script>

<style lang="scss">
.mx-task-label {
  font-size: 12px;
  height: 25px;
  width: 100%;
  padding: 15px;
  cursor: pointer;
  text-transform: uppercase;

  &.active {
    font-size: 11px;
    border-radius: 2px;
    transform: scale(1.075, 1.2);
    box-shadow: 0 0 24px rgba(169, 194, 209, .3), 0 0 4px rgba(169, 194, 209, .2);
  }
}

.theme--light {
  .mx-task-label {
    &.active {
      box-shadow: 0 0 24px rgba(169, 194, 209, .3), 0 0 4px rgba(169, 194, 209, .2);
    }
  }
}

.theme--dark {
  .mx-task-label {
    &.active {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
