const name = 'notice'

export default {
    "base_url": "",
    "view": {
        "name": name[0].toUpperCase() + name.slice(1),
    },
    "route": {
        "path": "notices",
        "name": name
    },
    "middleware": {
        "permission": name
    }
}