import { http } from "../http_service";

// User Auth
export function login(data) {
    return http().post(`/auth/login`, data);
}

export function passwordForgot(data) {
    return http().post(`/auth/password/forgot`, data);
}

export function passwordReset(data) {
    return http().post(`/auth/password/reset`, data);
}


export function logout() {
    return http().post(`/auth/logout`);
}

export function me() {
    return http().get(`/auth/me`);
}

export function test(id) {
    return http().get(`/auth/test/${id}`);
}