export default {
  "login": "Login",
  "logout": "Logout",
  "form": {
    "login": {
      "label": {
        "email": "Email",
        "password": "Password"
      },
      "required": {
        "email": "E-Mail is Required",
        "password": "Password is Required",
      },
      "error": "Couldn't sign you in with those details."
    }
  }
}
