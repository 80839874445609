import { http, httpFile } from "./http_service";


export function myTask(data) {
    return http().get(`/tasks/my-tasks`, data);
}

export function customerTask(customer_id) {
    return http().get(`/tasks/customer/${customer_id}`);
}

export function myObserveTasks(data) {
    return http().get(`/tasks/my-observe-tasks`, data);
}

export function create(data) {
    return httpFile().post(`/tasks`, data);
}

export function update(data) {
    return httpFile().post(`/tasks/${data.id}/update`, data.data);
}

export function getById(id) {
    return http().get(`/tasks/${id}/edit`);
}

export function deleteTaskById(id) {
    return http().delete(`/tasks/${id}`);
}


export function commentCreate(data) {
    return http().post(`/tasks/comments`, data);
}

export function getTaskComments(id) {
    return http().get(`/tasks/${id}/comments`);
}

export function getTaskTemplates() {
    return http().get(`/tasks/templates`);
}
export function TaskTemplatesCreate(data) {
    return http().post(`/tasks/templates`, data);
}

export function TaskTemplatesDelete(id) {
    return http().delete(`/tasks/${id}/templates`);
}

export function TaskTemplateGetById(id) {
    return http().get(`/tasks/${id}/templates`);
}

export function TaskTemplateUpdate(data) {
    return http().put(`/tasks/${data.id}/templates`, data);
}
