<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      min-width="300"
      offset-y
      transition="slide-y-reverse-transition"
  >
    <template v-if="header_settings.profile_avatar" v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="mr-2 d-flex">
        <v-img v-if="user.avatar !== null"   :src="options.avatar_url + user.avatar" max-height="36" max-width="36" style="border-radius: 8px"></v-img>
        <v-avatar rounded size="36" v-else height="36" width="36" color="primary">
          <span class="white--text">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
        </v-avatar>
        <v-icon size="20" class="ml-2">{{ menu ? 'fa-angle-up' : 'fa-angle-down' }}</v-icon>
      </div>
    </template>

    <template v-else v-slot:activator="{ on, attrs }">
      <div style="background: #3d3d3d; height: 48px;" class="d-flex align-center">
        <v-btn plain dark v-bind="attrs" v-on="on" :ripple="false" class="d-block mx-auto">
          <span class="mr-2">{{ user.first_name[0] }}.{{ user.last_name }}</span>
          <v-icon size="16">{{ menu ? 'fa-angle-up' : 'fa-angle-down' }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-avatar rounded color="primary" class="mr-4">
            <span class="white--text headline">{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ ucFirst(user.auth_role) }}</v-list-item-subtitle>
          </v-list-item-content>
<!--          <v-list-item-action>-->
<!--            <v-btn icon :class="fav ? 'primary&#45;&#45;text' : ''" @click="fav = !fav">-->
<!--              <v-icon size="28">mdi-shield-outline</v-icon>-->
<!--            </v-btn>-->
<!--          </v-list-item-action>-->
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
<!--        <v-list-item link to="/settings">-->
<!--          <v-list-item-action>-->
<!--            <v-icon class="mx-top-menu-action">mdi-tune-vertical</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Einstellung</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="white_theme" @change="switchTheme()" color="success"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Weißes Thema</v-list-item-title>
        </v-list-item>

        <v-list-item tag="a" @click="logout" class="error mt-3 mb-n2 " dark>
          <v-list-item-action>
            <v-icon class="mx-top-menu-action">mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('auth.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";

export default {
  components: { UserAvatarComponent },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      white_theme: true,
      user: null,
      header_settings: {}
    }
  },
  created() {
    this.white_theme = !this.$vuetify.theme.dark
    this.user = this.$store.getters.user
    this.header_settings = this.$store.getters.user_settings.header
  },
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.white_theme = !this.$vuetify.theme.dark
    },
    ucFirst(str) {
      if (!str) return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    logout() {
      this.$store.dispatch('signOut')
    }
  }
}
</script>
