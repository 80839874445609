<template>
  <div class="task-comment-list-item" :ref="`rf_comment_${comment.id}`" :id="`rfc_${comment.id}`">
    <div class="task-comment-list-item-creator">
      <UserAvatarComponent :avatar="comment.creator.avatar" :tooltip_disabled="true" :first_name="comment.creator.first_name" :last_name="comment.creator.last_name" />
      <div class="task-comment-list-item-creator-detail">
        <div class="task-comment-list-item-creator-detail--full_name">
          {{ comment.creator.first_name[0] }}.{{ comment.creator.last_name }}
        </div>
        <div class="task-comment-list-item-creator-detail--date">{{ getDate(comment.created_at) }}</div>
      </div>
    </div>
    <div class="task-comment-list-item-comment">
      <div class="task-comment-list-item-comment--container" v-html="textReplace(comment.text)">
<!--        {{ comment.text }}-->
      </div>
      <div v-if="false" class="task-comment-list-item-comment--action">
        <v-icon size="16" class="mr-3 mt-2">mdi-pencil-outline</v-icon>
        <v-icon size="16" class="mr-3 mt-2">mdi-trash-can-outline</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";
import moment from "moment";

export default {
  name: "TaskCommentListItemComponent",
  components: {
    UserAvatarComponent
  },
  props: {
    comment: {
      required: true
    }
  },
  methods: {
    getDate(date) {
      return moment(date).locale('de').format('DD MMMM, YYYY | HH:mm')
    },
    textReplace(text) {
      return text.replace(/(\r\n|\r|\n)/g, '<br>')
    },
  }
}
</script>

<style lang="scss">
.task-comment-list-item {
  display: flex;
  align-items: start;
  margin-bottom: 30px;
  &-creator {
    display: flex;
    margin-right: 20px;
    min-width: 200px;
    align-items: start;
    &-detail {
      margin-left: 10px;
      &--full_name {
        font-size: 14px;
        font-weight: 500;
      }
      &--date {
        margin-top: -2px;
        font-size: 13px;
      }
    }
  }
  &-comment {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &--container {
      padding: 20px;
      border-radius: 0 8px 8px 8px;
      font-size: 15px;
      line-height: 1.2rem !important;
    }

    &--action {
      display: flex;
      justify-content: end;
      opacity: 0;
      transition: opacity .250s ease-in;

      i {
        cursor: pointer;
      }
    }

    &:hover {
      .task-comment-list-item-comment--action {
        opacity: 1;
      }
    }
  }
}
.theme--light {
  .task-comment-list-item {
    &-creator {
      &-detail {
        &--date {
          color: #7e7e7e;
        }
      }

    }

    &-comment {
      &--container{
        background: #f5f5f5;
        color: #3d3d3d;
      }

      &--action {
        .v-icon {
          color: #b4b4b4;
          &:hover {
            color: #2f2f2f;
          }
        }
      }
    }
  }
}

.theme--dark {
  $color: #bcbcbc;
  .task-comment-list-item {
    &-creator {
      &-detail {
        &--date {
          color: $color;
        }
      }
    }

    &-comment {
      &--container{
        background: #363636;
        color: $color;
      }

      &--action {
        .v-icon {
          color: #565656;
          &:hover {
            color: #a9a9a9;
          }
        }
      }
    }
  }
}
</style>
