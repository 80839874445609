import user from './user/_lang_user'
import auth from './_lang_auth'

export default {
  "not_found": "Nicht gefunden",
  "user": user,
  "auth": auth,
  "filter": {
    "button": "Filter out"
  },
  "search": {
    "title": "Search from",
    "button": "search"
  }
}
