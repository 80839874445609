<template>
  <v-row class="mt-5">
    <v-col cols="12" class="mx-task-comment">
      <div ref="comment_list" class="mx-task-comment-list">
        <div class="d-flex align-center justify-center" style="height: 100%;" v-if="comments.length === 0">
          <h3>{{ $t('task.comment.no_comments_yet') }}</h3>
        </div>
        <template v-else>
          <div>
            <TaskCommentListItemComponent v-for="comment in comments" :key="comment.id" :comment="comment" />
          </div>
<!--          <div style="height: 1px;" ref="eld"></div>-->
        </template>
      </div>
      <div class="mx-task-comment-form" v-if="task_status !== 'completed'">
        <v-textarea
            v-model="form.text"
            :placeholder="$t('task.comment.form.placeholder')"
            hide-details
            rows="5"
            class="mx-task-comment-form--textarea"
            no-resize
            solo
        ></v-textarea>
        <v-btn :disabled="btn_disable" :loading="btn_loading" @click="save" color="primary" class="mt-1 ml-auto mx-task-comment-form--btn d-flex align-center">
          <span>{{ $t('task.comment.buttons.create') }}</span>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TaskCommentListItemComponent from "@/module/task/components/Comment/TaskCommentListItemComponent";

export default {
  name: "TaskCommentComponent",
  components: {
    TaskCommentListItemComponent
  },
  props: {
    props_comments: {
      type: Array,
      required: true,
      default: () => []
    },
    task_id: {
      required: true
    },
    task_status: {
      required: false,
      default: 'todo'
    }
  },
  data() {
    return {
      comments1: [
        {id: 1, created_at: '2021-12-21 09:15', creator: {avatar: 'i/e/a/0/26itW62.jpg', first_name: 'Alexander', last_name: 'Bechthold'}, text: 'But also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'},
        {id: 2, created_at: '2022-02-21 09:15', creator: {avatar: 'i/e/a/0/1KxWWx1.jpg', first_name: 'Michelle', last_name: 'Beinhauer'}, text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'},
        {id: 3, created_at: '2022-02-22T11:02:49.000000Z', creator: {avatar: 'i/e/a/0/26itW62.jpg', first_name: 'Alexander', last_name: 'Bechthold'}, text: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. '},
      ],
      btn_loading: false,
      btn_disable: true,
      comments: this.props_comments,
      form: {
        text: '',
        creator_id: this.$store.getters.user.id,
        task_id: this.task_id,
        is_observer: false,
      }
    }
  },
  watch: {
    'form.text'() {
      if(this.form.text.length >= 10) {
        this.btn_disable = false
      } else {
        this.btn_disable = true
      }
    }
  },
  methods: {
    save() {
      this.btn_loading = true
      this.btn_disable = true

      if (this.form.text.length < 10) {
        this.btn_loading = false
        this.btn_disable = false
        return
      }

      if (this.$store.getters.getIsObserve) {
        this.form.is_observer = true
        this.form.observer_uid = this.$store.getters.user.uid
      }

      this.$store.dispatch('taskCommentCreate', this.form).then(response => {
        this.$store.dispatch('getTaskComments', this.form.task_id).then(() => {
          this.comments = this.$store.getters.getTaskComments
          this.form.text = ''

         setTimeout(() => {
           this.scrollToElement(response.data.data.id)
           this.btn_loading = false
           this.$emit('taskCommitUpdated', true)
         }, 300)

        }).catch((error) => {
          this.btn_loading = false
          this.btn_disable = false
          this.$emit('taskCommitUpdated', false)
        })
      })

    },
    scrollToElement(e) {
      const element = document.getElementById(`rfc_${e}`);
      const top = element.offsetTop - 100;

      this.$refs['comment_list'].scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>


<style lang="scss">
.mx-task-comment {
  display: flex;
  flex-direction: column;
  height: 530px;

  &-list {
    overflow-y: auto;
    margin-bottom: 20px;
    padding-right: 10px;
    flex-grow: 1;
  }

  &-form {
    height: 240px;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    //flex-direction: column;

    &--textarea {
      border-radius: 0 8px 8px 8px;
      font-size: 15px;
      margin-right: 20px !important;

      textarea {
        line-height: 1.2rem !important;
      }
    }

    &--btn{
      padding: 22px 12px !important;

    }
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #f5f5f5;

  }

  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #363636;
  }

}
</style>